.color-green {
  --ion-color-base: var(--ion-color-green);
}

.color-white {
  color: var(--ion-color-primary-contrast);
}

.color-mea {
  color: var(--mea-dark)
}

.color-danger {
  color: var(--ion-color-danger)
}

.color-medium {
  color: var(--ion-color-medium);
}
