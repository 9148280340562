// Default colors
.badge-color-blue {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.badge-color-blue-border {
  background-color: var(--status-colors-blue);
  border: 2px solid var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.badge-color-grey {
  background-color: var(--status-colors-grey);
  color: var(--status-colors-grey-dark);
}

.badge-color-grey-dark {
  background-color: var(--ion-color-light-shade);
  color: var(--ion-color-dark-tint);
}
.badge-color-yellow {
  background-color: var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}
.badge-color-magenta {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.badge-color-green {
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.badge-color-purple {
  background-color: var(--status-colors-purple);
  color: var(--status-colors-purple-dark);
}
.badge-color-purple-border {
  background-color: var(--status-colors-purple);
  border: 2px solid var(--status-colors-purple);
  color: var(--status-colors-purple-dark);
}

.badge-color-orange {
  background-color: var(--status-colors-orange);
  color: var(--status-colors-orange-dark);
}



// delivery status (orders table)
.delivery-status-active {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.filter-result,
.delivery-status-cancelled {
  background-color: var(--status-colors-grey);
  color: var(--status-colors-grey-dark);
}
.user-administration {
  background-color: var(--ion-color-light-shade);
  color: var(--ion-color-dark-tint);
  height: 19px;
  ion-icon {
    color: var(--ion-color-dark-tint);
    margin-left: -3px!important;
  }
}
.delivery-status-completed {
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.delivery-status-enroute, .delivery-status-pending, .delivery-status-delegated, .delivery-status-partially-completed {
  background-color: var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}
.delivered-items {
  background-color: transparent;
  color: var(--ion-color-medium);
  border: 1px solid var(--ion-color-medium) !important;
}

// delivery receipt sub-types
.delivery-receipt-subtype-chartier {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.delivery-receipt-subtype-charblut {
  background-color: var(--status-colors-orange);
  color: var(--status-colors-orange-dark);
}
.delivery-receipt-subtype-chardoku {
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.delivery-receipt-subtype-charsecu {
  background-color: var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}

// invoice types
.invoices-type-collective {
  background-color: var(--status-colors-blue);
  border: 2px solid var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.invoices-type-partial {
  background-color: var(--status-colors-purple);
  border: 2px solid var(--status-colors-purple);
  color: var(--status-colors-purple-dark);
}
.invoices-type-confirmationOfBalance {
  background-color: var(--status-colors-yellow);
  border: 2px solid var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}

// returns status
.returns-status-destroyed, .returns-status-cancelled, .returns-status-returnMissing, .returns-status-goodsReturned {
  background-color: var(--status-colors-grey);
  color: var(--status-colors-grey-dark);
}
.returns-status-credited, .returns-status-withoutCalculation {
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.returns-status-processing {
  background-color: var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}

// returns type
.returns-type-recall, .returns-type-stockDefect, .returns-type-withdrawal, .returns-type-return {
  background-color: unset;
  color: var(--ion-text-color);
}

// user administration access rights badges
.user-administration-sconnect {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.user-administration-chat {
  background-color: var(--user-administration-chat-color);
  color: var(--ion-color-primary-contrast);
}
.user-administration-shop {
  background-color: var(--user-administration-shop-color);
  color: var(--ion-color-primary-contrast);
}
.user-administration-sacademy {
  background-color: var(--sanacorp-academy-color);
  color: var(--ion-color-primary);
}
.user-administration-sanavendi {
  background-color: var(--ion-color-blue);
  color: var(--ion-color-blue-contrast);
}
.user-administration-disabled, .user-administration-deactivated {
  background-color: var(--badge-disabled-color);
  color: var(--status-colors-grey-dark);
}

// order bulk action
.order-bulk-action-default {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.order-bulk-action-cancel {
  background-color: var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}
.order-bulk-action-reorder {
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.order-bulk-action-extend {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.order-bulk-action-arrived {
  background-color: var(--status-colors-orange);
  color: var(--status-colors-orange-dark);
}

// NOTIFICATIONS
.notification-badge-common {
  background-color: var(--status-colors-grey);
  color: var(--status-colors-grey-dark);
}
.notification-badge-meamind,
.notification-badge-meadirect,
.notification-badge-meashop{
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.notification-badge-invoice {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.notification-badge-news,
.notification-badge-survey,
.notification-badge-offer {
  background-color: var(--status-colors-purple);
  color: var(--status-colors-purple-dark);
}
.notification-badge-order {
  background-color: var(--status-colors-orange);
  color: var(--status-colors-orange-dark);
}
.notification-badge-statistics,
.notification-badge-appointment {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}

// mea Downloads
.mea-downloads-marketing {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
.mea-downloads-info {
  background-color: var(--status-colors-purple);
  color: var(--status-colors-purple-dark);
}

.mea-downloads-default {
  background-color: var(--status-colors-grey);
  color: var(--status-colors-grey-dark);
}
.mea-downloads-mea {
  background-color: var(--status-colors-green);
  color: var(--status-colors-green-dark);
}
.mea-downloads-darkPink {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.mea-downloads-purple {
  background-color: var(--status-colors-purple);
  color: var(--status-colors-purple-dark);
}
.mea-downloads-yellow {
  background-color: var(--status-colors-yellow);
  color: var(--status-colors-yellow-dark);
}
.mea-downloads-orange{
  background-color: var(--status-colors-orange);
  color: var(--status-colors-orange-dark);
}

.mea-downloads-blue {
  background-color: var(--status-colors-blue);
  color: var(--status-colors-blue-dark);
}
