ion-popover.popover-auto-width {
  width: auto!important;
  --width: auto;
  --min-width: 260px;
  --max-width: auto;
}

ion-popover {
  --max-height: 410px;
}

ion-popover.inline-style ion-item {
  --background: var(--ion-color-background);
  --background-hover: var(--ion-color-light);
  --background-hover-opacity: 0.5;
  --border-style: none;
  --transition: background 250ms ease-in-out;
  font-size: var(--ion-font-size-subheadline);
  font-weight: var(--ion-font-weight-subheadline);
  padding: .25rem .75rem;
}

.returns-popover ion-item:last-of-type {
    --border-style: none;
}

app-input-popover {
  --autocomplete-input-background-color: var(--ion-color-primary-contrast);
  --autocomplete-input-padding: var(--ion-padding-small) var(--ion-padding-extra-big) var(--ion-padding-small) var(--ion-padding-big);
  --autocomplete-input-border-width: 2px;
  --autocomplete-border-color: var(--ion-color-dark);
  --autocomplete-border-radius: 0;
}

.p-autocomplete {
  width: 100%;
  height: 100%;

  .p-autocomplete-input {
    width: 100%;
    height: 100%;
    padding-top: var(--ion-padding-small);
    padding-bottom: var(--ion-padding-small);
    padding-right: var(--ion-padding-extra-big);
    // create same space between search-icon and input text
    // because icon positioned absolute
    padding-left: calc(var(--ion-padding-big) + var(--ion-padding-small) - 1px);
    background-color: var(--autocomplete-input-background-color);
    border: none;
    border-bottom: var(--autocomplete-input-border-width) solid var(--autocomplete-border-color);
    border-radius: var(--autocomplete-border-radius);
    z-index: 1003;    // overlay has 1002
    text-overflow: ellipsis;
    font-weight: var(--orders-page-font-weight);

    &.searching {
      border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    }

    &.search-icon-end {
      padding-left: var(--ion-padding-medium);
    }

    &.dropdown-visible {
      border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    }

    &::placeholder {
      color: var(--ion-color-medium) !important;
      font-size: var(--orders-page-font-size);
      font-weight: var(--orders-page-font-weight);
    }
  }

  .p-autocomplete-clear-icon {
    width: var(--ion-font-size-headline);
    height: var(--ion-font-size-headline);
    // 2px to generate same space as the search icon
    right: calc(var(--ion-padding-small) + 2px);
    // vertically center icon
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);

    bottom: var(--search-icon-bottom);
    color: var(--ion-color-black);
    z-index: 1003;    // display on top of input
    transition: background 0.2s ease-in-out,
                color 0.2s ease-in-out;
  }

  .p-autocomplete-loader {
    right: var(--ion-padding-small);
    top: var(--ion-padding-small);
    height: 100%;
    color: var(--ion-color-primary);
    z-index: 1003;    // display on top of input
    animation: loading-spinner 1s linear infinite;
  }

  @keyframes loading-spinner {
    to {
        transform: rotate(360deg);
    }
  }
}

div.p-overlay.p-component {
  width: min-content;
}

.p-overlay-content {
  overflow: hidden;
  border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
  box-shadow: var(--default-box-shadow);

  .p-autocomplete-panel {
    background-color: var(--ion-color-dark-contrast);
  }
}

.p-autocomplete-items {
  padding: 0;
}

.p-autocomplete-item {
  padding: var(--ion-padding-medium);
  margin: 0 0.25rem;  // same value as ionic uses
  border-bottom: 1px solid var(--ion-color-light);
  transition: border-bottom 0.4s ease-in-out,
  background-color 0.25s ease-in-out;

  &:hover, &.p-focus {
    background-color: rgb(var(--ion-color-light-rgb), 0.35);
    border-bottom: 1px solid var(--ion-color-light-shade);
  }

  &:last-of-type {
    border-bottom: none;
  }

  span {
    white-space: normal;
  }
}

.p-autocomplete-empty-message {
  padding: var(--ion-padding-small);
  text-align: center;
}

.autocomplete-footer {
  width: 100%;
  padding: var(--ion-padding-small);
  color: var(--ion-color-medium);
  text-align: center;
}

ion-popover.select-popover-positioning-start {
    transform: translate(calc(-1 * var(--ion-padding-medium)), var(--ion-padding-small-big));
}
