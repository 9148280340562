
.border-color-marketing {
  border-color: var(--status-colors-blue);
}
.border-color-info {
  border-color: var(--status-colors-purple);
}
.border-color-default {
  border-color: var(--status-colors-grey);
}
.border-color-mea {
  border-color: var(--status-colors-green);
}
.border-color-darkPink {
  border-color: var(--ion-color-primary);
}
.border-color-purple {
  border-color: var(--status-colors-purple);
}
.border-color-yellow {
  border-color: var(--status-colors-yellow);
}
.border-color-orange{
  border-color: var(--status-colors-orange);
}
.border-color-blue {
  border-color: var(--status-colors-blue);
}
