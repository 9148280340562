.highlight-pulse {
  --highlight-color: var(--ion-color-primary-rgb);
  animation: highlight-pulse 1s 3;
}

.badge-highlight-pulse ion-badge {
 @extend .highlight-pulse;
}

@keyframes highlight-pulse {
  0% {
    box-shadow: rgba(var(--highlight-color, var(--ion-color-primary-rgb)), 1) 0 0 0 0;
  }
  100% {
    box-shadow: rgba(var(--highlight-color,var(--ion-color-primary-rgb)), 0) 0 0 0 15px;
  }
}

