@import './app/src/theme/mixins/media-query-mixin';

* {
  outline: none;
}

[hidden] {
  display: none !important;
}

.display-flex {
  display: flex;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}
.display-inline-block {
  display: inline-block;
}
.display-block {
  display: block;
}
.justify-content-end {
  justify-content: end;
}
.no-overflow {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
  --overflow: auto;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-100 {
  flex: 1 1 100%;
}
.flex-50 {
  flex: 1 1 50%;
}
.min-height {
  min-height: 65vh;
}
.min-height-60 {
  min-height: 60vh;
}
.max-width-100 {
  max-width: 100%;
}
.width-fit-content {
  width: fit-content;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-direction-column {
  flex-direction: column;
}
.clickable {
  cursor: pointer;
}
.margin-left-auto {
  margin-left: auto;
}
.margin-left-small {
    margin-left: var(--ion-margin-small);
}
.margin-right-small {
  margin-right: var(--ion-margin-small);
}
.margin-top-small {
  margin-top: var(--ion-margin-small);
}
.margin-bottom-small {
  margin-bottom: var(--ion-margin-small);
}

.margin-left-big {
    margin-left: var(--ion-margin-big);
}
.margin-right-big {
  margin-right: var(--ion-margin-big);
}
.margin-top-big {
  margin-top: var(--ion-margin-big);
}
.margin-bottom-big {
  margin-bottom: var(--ion-margin-big);
}

.clickable-animated {
  cursor: pointer;
  transition: box-shadow 250ms ease-in-out;

  &:hover {
    box-shadow: var(--default-box-shadow-dark);
  }
}

.no-background {
  background-color: transparent !important;
}

.no-padding-vertical-mobile {
  @include media-query-normal-tablet {
    padding-top: 0;
    padding-bottom: 0;
  }
  @include media-query-small-tablet-and-below {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.widget-text-element {
  font-size: var(--ion-font-size-small-text);
}

.header-md:after {
  background-image: none;
}

.header-ios, .header-md {
  box-shadow: none !important;
  ion-toolbar:last-child {
    --border-width: 0 0 1px;
  }
}

.actions-container {
  padding: 0.15rem 0;

  ion-button.button-small {
    font-size: var(--ion-font-size-small-text);
  }
}

.default-content-container {
  padding: 2rem;
  height: 100%;
  @include media-query-normal-tablet {
    padding: 1rem 2rem;
  }
}

.router-page-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.donut-chart-primary-color {
  stop-color: var(--ion-color-primary);
}
.donut-chart-secondary-color {
  stop-color: var(--ion-color-secondary);
}

.bg-color-light {
  background-color: var(--ion-color-light);
  --background: var(--ion-color-light);
}



.col-border-right {
  border-right: var(--default-border-width) solid var(--ion-color-medium);
}

.ion-padding-bottom-half {
  --padding-bottom: calc(var(--ion-padding, 16px) / 2);
  padding-bottom: calc(var(--ion-padding, 16px) / 2);
}
.ion-padding-top-half {
  --padding-top: calc(var(--ion-padding, 16px) / 2);
  padding-top: calc(var(--ion-padding, 16px) / 2);
}
.ion-padding-left-half {
  --padding-left: calc(var(--ion-padding, 16px) / 2);
  padding-left: calc(var(--ion-padding, 16px) / 2);
}
.ion-padding-right-half {
  --padding-right: calc(var(--ion-padding, 16px) / 2);
  padding-right: calc(var(--ion-padding, 16px) / 2);
}
.ion-padding-right-medium {
  padding-right: var(--ion-padding-medium) !important;
}

.ion-padding-big {
  --padding: calc(var(--ion-padding, 16px) * 2);
    padding: calc(var(--ion-padding, 16px) * 2);
}

.ion-padding-bottom-big {
  --padding-bottom: calc(var(--ion-padding, 16px) * 2);
  padding-bottom: calc(var(--ion-padding, 16px) * 2);
}

.ion-padding-top-big {
  --padding-top: calc(var(--ion-padding, 16px) * 2);
  padding-top: calc(var(--ion-padding, 16px) * 2);
}

.ion-padding-left-big {
  --padding-left: calc(var(--ion-padding, 16px) * 2);
  padding-left: calc(var(--ion-padding, 16px) * 2);
}

.ion-padding-right-big {
  --padding-right: calc(var(--ion-padding, 16px) * 2);
  padding-right: calc(var(--ion-padding, 16px) * 2);
}

.ws-normal {
  white-space: normal;
}


.bolder {
  font-weight: var(--ion-font-weight-bolder);
}
.underline {
  text-decoration: underline;
}
.no-underline {
  text-decoration: unset;
}

.full-opacity {
  opacity: 1 !important;
}

.img-is-logo {
  padding: var(--ion-padding-medium);
}
/*
TODO has to be change everywhere, on hold for the moment

.scrollbar {
  scrollbar-color: var(--ion-color-primary) rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
}
*/
