@import '../mixins/media-query-mixin.scss';

.content-wrapper.content-wrapper-menu-active {
    app-banner {
        //min-width: 1650px comes from dashboard.page.scss .banner
        @media (min-width: 1650px) {
            .oneColumnLayout.vertical-banner  {
                display: block;
            }

            .mobile-button {
                display: none;
            }
        }
    }
    
    app-dashboard .content #dashboard.dashboard {
        @media (min-width: 1650px) {
            max-width: min(1300px,
            calc(100vw - var(--vertical-banner-width) - var(--menu-expanded-width) - var(--ion-padding-big) - 2px));
            // 2px to account for scrollbar width
        }
    }
}

.content-wrapper {
  app-dashboard .content #dashboard.dashboard {
    @include media-query-web {
      max-width: min(1300px,
      calc(100vw - var(--vertical-banner-width) - var(--ion-padding-big) - 2px));
      // 2px to account for scrollbar width
    }
  }
}

.content-wrapper:not(.content-wrapper-menu-active) {
  app-banner {
    @include media-query-web {
      .oneColumnLayout.vertical-banner  {
        display: block;
      }

      .mobile-button {
        display: none;
      }
    }
  }
  app-dashboard .content #dashboard.dashboard {
    @include media-query-web {
      max-width: min(1300px,
      calc(100vw - var(--vertical-banner-width) - var(--menu-contracted-width) - var(--ion-padding-big) - 2px));
      // 2px to account for scrollbar width
    }
  }
}
