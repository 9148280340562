ion-popover.user-popover {
  --backdrop-opacity: 0;
  --box-shadow: var(--user-popover-box-shadow); // default ionic-popover shadow has to be overwritten here for mobile to be displayed
  --max-height: 80vh;
  --width: auto;

  &::part(content) {
    top: 8px !important; /* Style set by js, override only possible with important */
    right: 15px !important;
    width: auto;
  }
}
