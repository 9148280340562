.ios, .md {
  ion-card {
    border-radius: var(--default-border-radius);
    --color: var(--ion-color-dark);
    box-shadow: var(--default-box-shadow);
  }
  ion-card-header {
    flex-direction: row;
    &.flex-direction-column {
      flex-direction: column;
    }
  }
  ion-card-content {
    &.card-content-md p,
    &.card-content-ios p {
      font-size: var(--ion-font-size-text);
    }
  }
}
