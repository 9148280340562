@import './app/src/theme/mixins/media-query-mixin';

.label {
  color: var(--ion-color-dark);
  font-weight: var(--ion-font-weight-headline);
  font-size: var(--ion-font-size-text);
}

.filter-label {
  display: block;
  color: var(--ion-color-medium);
  font-size: var(--orders-page-font-size);
  font-weight: var(--orders-page-font-weight);
  margin-bottom: .25rem;

  @include media-query-normal-tablet {
    margin-bottom: 0;
  }
}

.filter-text {
  color: var(--ion-color-dark);
  font-size: var(--orders-page-font-size);
  font-weight: var(--orders-page-font-weight);
  cursor: pointer;
  margin-bottom: var(--ion-margin-small);
  display: flex;
  align-items: center;

  &.disabled {
    cursor: unset;
  }

  @include media-query-normal-tablet {
    margin: .2rem 0;
  }

  ion-icon {
    margin-left: var(--ion-margin-small);
  }
}

app-table-header .filter-text {
  margin-bottom: 0;
}

.filter-inline {
  display: flex;
  .filter-text {
    margin: 0 .25rem;
  }
}


.filter-clear-wrapper {
  display: flex;
  align-items: center;
  margin-right: var(--ion-margin-medium);

  ion-icon:not([name='caret-down-sharp']) {
    font-size: var(--ion-font-size-headline);
    display: block;
    cursor: pointer;
    margin-left: var(--ion-margin-small);
    // Prevent line height extension
    margin-bottom: -4px;
    margin-top: -4px;
  }
}

.h1, h1 {
  font-size: var(--ion-font-size-headline);
  font-weight: var(--ion-font-weight-headline);
}

.h2, h2 {
  font-size: var(--ion-font-size-subheadline);
  font-weight: var(--ion-font-weight-subheadline);

  &.bold {
    font-weight: var(--ion-font-weight-headline);
  }
}
.h3, h3 {
  font-size: var(--ion-font-size-text);
  font-weight: var(--ion-font-weight-headline);

  &.sub {
    color: var(--ion-color-medium);
  }
}
.h4, h4 {
  font-size: var(--ion-font-size-text);
  font-weight: var(--ion-font-weight-subheadline);

  &.sub {
    color: var(--ion-color-medium);
  }
}
.h1-big {
  font-size: var(--ion-font-size-headline-bigger);
  font-weight: var(--ion-font-weight-headline);
}
.h1-bigger {
  font-size: var(--ion-font-size-big);
  font-weight: var(--ion-font-weight-boldest);
}

.card-title {
  font-size: var(--ion-font-size-subheadline-bigger);
  font-weight: var(--ion-font-weight-headline);

  @include media-query-small-tablet-and-below {
    font-size: var(--ion-font-size-subheadline);
  }

  @include media-query-normal-tablet {
    font-size: var(--ion-font-size-subheadline);
  }
}

.fw-boldest {
  font-weight: var(--ion-font-weight-boldest) !important;
}

.fw-bolder {
  font-weight: var(--ion-font-weight-bolder) !important;;
}

.fw-headline {
  font-weight: var(--ion-font-weight-headline) !important;;
}

.fw-subheadline {
  font-weight: var(--ion-font-weight-subheadline) !important;;
}

.fw-text {
  font-weight: var(--ion-font-weight-text) !important;
}

.fw-small-text {
  font-weight: var(--ion-font-weight-small-text) !important;;
}

.fs-subheadline-bigger {
  font-size: var(--ion-font-size-subheadline-bigger) !important;;
}
.fs-small-text {
  font-size: var(--ion-font-size-small-text) !important;;
}

.fs-extra-small-text {
  font-size: var(--ion-font-size-text-smaller) !important;;
}


.no-results-label {
  font-size: var(--ion-font-size-headline);
  width: 100%;
  text-align: center;
  display: block;
  padding: var(--ion-padding-big);
}

.statistic-single-value {
  font-size: var(--ion-font-size-big-bigger);
  font-weight: var(--ion-font-weight-boldest);
}

.statistic-single {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .statistic-single-changes {
    padding: var(--ion-padding-medium);
    display: flex;

    ion-icon{
      font-size: var(--ion-font-size-headline);
    }

    .statistic-single-calc {
      font-weight: var(--ion-font-weight-boldest);
    }

    ion-icon, .statistic-single-calc {
      margin-right: var(--ion-margin-small);
    }
  }
}


.statistic-legend-marker {
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    display: block;
    height: 7px;
    width: 7px;
    background-color: var(--ion-color-medium-shade);
  }

  &.statistic-legend-marker-secondary:before {
    background-color: var(--ion-color-primary);
  }
  &.is-large{
    padding-left: 20px;

    &:before {
      height: 12px;
      width: 12px;
    }
  }
}

.important-notice {
  border-radius: var(--default-input-border-radius);
  --border-radius: var(--default-input-border-radius);
  background: rgba(var(--ion-color-primary-light-rgb),.8);
  color: var(--ion-color-primary);
  padding: var(--ion-padding-medium) var(--ion-padding-medium-big);
  font-size: var(--ion-font-size-text-smaller);
  line-height: 1.6;
  display: block;
}

.inline-link {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.inline-link-dark {
  color: var(--ion-color-dark);
  cursor: pointer;
  transition: color 250ms ease-in-out;

  &:hover {
    color: var(--ion-color-primary);
  }
}

.required-star {
  font-size: var(--ion-font-size-form);
  color: var(--ion-color-primary);
  font-weight: var(--ion-font-weight-headline);
  margin-right: 1px;
}

ion-icon.disabled {
  color: var(--ion-color-medium-tint);
}
