ion-segment {
  &:not(.flat) {
    &.ios, &.md {
      grid-auto-columns: auto;
      width: auto;
      border-radius: var(--default-border-radius);
      border: 1px solid rgb(var(--theme-color, var(--ion-color-primary-rgb)));

      ion-segment-button {
        --border-radius: none;
        color: rgb(var(--theme-color, var(--ion-color-primary-rgb)));
        border-color: rgb(var(--theme-color, var(--ion-color-primary-rgb)));
        margin: 0;
        height: 23px;
        min-height: 23px;
        --padding-start: 5px;
        --padding-end: 5px;

        &.segment-button-checked {
          background: rgb(var(--theme-color, var(--ion-color-primary-rgb)));
          color: var(--theme-color-contrast, var(--ion-color-primary-contrast));
          border-color: rgb(var(--theme-color, var(--ion-color-primary-rgb)));
          --indicator-color: transparent;
          --border-color: transparent;
          --color-hover: var(--theme-color-contrast, var(--ion-color-primary-contrast));
          --color-checked: var(--theme-color-contrast, var(--ion-color-primary-contrast));
        }

        ion-label {
          font-size: var(--ion-font-size-text-smaller);
          font-weight: var(--ion-font-weight-headline);
        }
      }
    }
  }

  &.flat {
    &.ios, &.md {
      grid-auto-columns: auto;
      width: auto;

      ion-segment-button {
        --border-radius: none;
        --border-width: 0;

        &::part(indicator) {
          top: auto;
        }
        &::part(indicator-background) {
          background: var(--ion-color-primary);
          height: 2px;
        }

        &::part(native) {
          --padding-start: var(--ion-padding-small);
          --padding-end: var(--ion-padding-small);
          color: var(--ion-color-dark);
        }
        &.segment-button-checked ion-label {
          color: var(--ion-color-primary);
        }

        ion-label {
          font-size: var(--ion-font-size-text-smaller);
          font-weight: var(--ion-font-weight-headline);
        }
      }
    }
  }
}
