ion-modal {
  // this is necessary 2 modals opened on the same time (like in new calendar appointment + selecting date/time-modal)
  // keep this the same as in ionic-framework/core/src/components/modal/modal.md.vars.scss
  --box-shadow: 0 28px 48px rgba(0, 0, 0, .4) !important;
  --border-radius: var(--default-border-radius);
}

.sc-ion-modal-md-h,
.sc-ion-modal-ios-h {
  --max-width: 100vw;
}

app-modal {
  height: 100%;
  min-height: 10vh;
}

div.modal-wrapper.sc-ion-modal-ios {
  border-radius: unset;
}

.modal-large {
  --width: 80vw;
  --max-width: 1300px;
  @include media-query-normal-tablet {
    --width: 90vw;
    --height: 90vh;
  }
}
.modal-medium {
  --width: 60vw;
  --max-width: 800px;
}

.modal-medium-high {
  --width: 60vw;
  --max-width: 1000px;
  --max-height: 75vh;
  --height: 75vh;
  @include media-query-normal-tablet {
    --width: 90vw;
    --height: 90vh;
  }
}

.modal-alert {
  --max-height: 260px;
}

.modal-notification-center {
  --min-height: 768px;
  --height: 70vh;
  --max-height: 900px;
  --min-width: 900px;
  --width: 80vw;
  --max-width: 1000px;
  @include media-query-normal-tablet {
    --width: 90vw;
    --height: 90vh;
    --max-height: 90vh;
    --min-height: 80vh;
  }
}

.modal-info-modal {
  --min-height: 568px;
  --height: 70vh;
  --max-height: 800px;
  --min-width: 700px;
  --width: 80vw;
  --max-width: 900px;
  @include media-query-normal-tablet {
    --width: 90vw;
    --height: 90vh;
    --max-height: 90vh;
    --min-height: 80vh;
  }
}

.modal-high {
  --max-height: 85vh;
  --height: 85vh;
}

.modal-footer {

  ion-button {
    margin-top: 0;
    margin-bottom: 0;
  }
}


ion-modal.modal-auto-height {
  --height: auto;
  .ion-page {
    position: relative;
    contain: content;
    max-height: 90vh;
    .modal-content {
      overflow: auto;
      max-height: 70vh; // necessary for the modal content to scroll, when content doesn't fit max height
    }
  }
}

ion-modal.modal-auto-width {
  --width: auto;
  &.modal-high {
    --heigth: 85vh;
    .ion-page {
      height: 85vh;
    }
  }

  .ion-page {
    position: relative;
    contain: content;
    --width: 10vw;
    .modal-content {
      overflow: auto;
    }
  }
}

ion-modal.modal-overflow-visible {
  --overflow: visible;
  contain: inherit;

    .ion-page {
      overflow: visible;
      contain: inherit;
    }
}

ion-modal.modal-flex-end {
  justify-content: flex-end;
  padding-right: var(--ion-width-big);
}

@include media-query-small-tablet-and-below {
  ion-modal .ion-delegate-host.ion-page {
    display: flex;
  }
}

.info-modal-list > li {
  margin-bottom: var(--ion-margin-medium);
  &:last-of-type {
    padding-bottom: var(--ion-padding-medium);
  }
}

.info-modal-heading {
  margin-top: var(--ion-margin-extra-small);
}

.info-content-main p {
  margin: 0;
}
