// When changing breakpoints, please change values in "screen-sizes.enum.ts" as well.

/**
 * Media query for mobile devices
 */
@mixin media-query-mobile {
    @media only screen and (max-width: 767px) {
        @content
    }
}
@mixin media-query-small-tablet-and-below {
    @media only screen and (max-width: 1024px) {
        @content
    }
}

/**
 * Media query for screens up to 1280px
 */
@mixin media-query-1280 {
    @media only screen and (max-width: 1280px) {
        @content
    }
}

/**
 * Media query for 9,7 inches tablets (iPad)
 */
@mixin media-query-small-tablet {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        @content
    }
}

/**
 * Media query for 10+ inches tables (Samsung Tab 5)
 */
@mixin media-query-normal-tablet {
    @media only screen and (min-width: 1024px) and (max-width: 1288px) {
        @content
    }
}

/**
 * Media query for 12+ inches tables (iPad Pro)
 */
@mixin media-query-big-tablet {
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
        @content
    }
}

/**
 * Media query for Web
 */
@mixin media-query-web {
    @media only screen and (min-width: 1367px) {
        @content
    }
}

/**
 * Media query for main navigation bar
 */
@mixin media-query-navigation-bar-height {
    @media only screen and (max-height: 890px) {
        @content
    }
}
