@import './app/src/theme/mixins/media-query-mixin';

.value-addition {
  font-size: var(--ion-font-size-text);
  font-weight: var(--ion-font-weight-headline);
  line-height: 1.2;

  p {
    font-size: var(--ion-font-size-subheadline);
    font-weight: var(--ion-font-weight-bolder);
    margin: 0;
    line-height: 1;

    span:first-of-type {
      font-size: var(--ion-font-size-icon-number-boxes);

      @include media-query-normal-tablet {
        font-size: var(--ion-font-size-icon-number-boxes-tablet);
      }
      @include media-query-small-tablet-and-below {
        font-size: var(--ion-font-size-icon-number-boxes-tablet);
      }
    }
    span:last-of-type {
      font-size: var(--ion-font-size-headline-bigger);
      font-weight: var(--ion-font-weight-headline);

      @include media-query-normal-tablet {
        margin-left: 10px;
      }
    }
  }
}

app-widget-small ion-col {
  height: 100%;
}
