swiper-container::part(container) {
  overflow-y: auto;
}


.slider-nav-buttons {
  position: relative;
  z-index: 4;
  background-color: var(--ion-color-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.is-horizontal {
    flex-direction: row-reverse;

    ion-button, ion-button:first-child {
      margin-bottom: 0;
    }
  }

  ion-button {
    background-color: var(--ion-color-primary-contrast);
    color: rgb(var(--theme-color));
    border-radius: 50%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    transition: background-color 250ms ease-in-out,
                color 250ms ease-in-out;

    &:first-child {
      margin-bottom: 1.5rem;
    }

    &[disabled],
    &.swiper-button-disabled {
      background-color: var(--ion-color-light-shade);
      color: var(--ion-color-medium);
      cursor: default;
    }

    &:not(.swiper-button-disabled):hover {
      background-color: rgb(var(--theme-color));
      color: var(--ion-color-primary-contrast);
    }
  }
}
