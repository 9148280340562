.dot {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  background: rgba(var(--ion-color-primary-rgb), .075);

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--ion-color-primary);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
