ion-select {
  min-height: unset;
  padding: var(--ion-padding-medium) var(--ion-padding-big);
  --highlight-color-focused: transparent;

  &::part(container) {
    width: 100%;
    padding-right: var(--ion-padding-medium-big);
  }

  &::part(icon) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &::part(icon) {
    color: var(--ion-color-dark);
    opacity: 0.55;
  }
}

ion-select.select-filter {
  color: var(--ion-color-dark);
  background: var(--ion-color-light);
  border-radius: var(--default-border-radius);
  font-weight: var(--ion-font-weight-subheadline);
  font-size: var(--ion-font-size-text);

  &.select-filter-no-underline {
    --highlight-height: 0;
  }
}

ion-select.select-default {
  max-width: 335px;
  margin-top: 10px;
  color: var(--ion-color-dark);
  background: var(--ion-color-light);
  border-radius: var(--default-border-radius);
  font-weight: var(--ion-font-weight-subheadline);
  font-size: var(--ion-font-size-text);
}

ion-popover ion-select-popover {
  ion-list.list-md, ion-list.list-ios {
    padding: 0;

    ion-item:last-child {
      --inner-border-width: 0;
    }
  }
}
