.video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.video {
  cursor: pointer;

  .video-js:hover .vjs-big-play-button {
    background-color: rgba(var(--ion-color-primary-rgb), 1);
    transition: background-color 250ms ease-in-out;
  }

  .vjs-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(var(--ion-color-primary-rgb), .7);
    box-shadow: 3px 6px 17px 0 rgba(var(--ion-color-primary-rgb), .5);
    border: none;
    transition: background-color 250ms ease-in-out;

    .vjs-icon-placeholder:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: auto;
      font-size: 30px;
    }
  }
}