.modal-list {
  ion-text {
    display: block;
    font-family: var(--ion-font-family,inherit);
    font-size: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    margin: var(--ion-margin-small) 0;

    h2 {
      margin: var(--ion-margin-extra-small) 0;
      font-size: var(--ion-font-size-subheadline);
      font-weight: var(--ion-font-weight-text);
    }

    p {
      margin: 0 0 var(--ion-margin-extra-small);
      font-size: var(--ion-font-size-text);
      font-weight: var(--ion-font-weight-text);
      line-height: 1.4;
      text-overflow: inherit;
      overflow: inherit;
    }
  }
}

ul {
  padding-left: 1rem;
}
