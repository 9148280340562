/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/mixins/media-query-mixin";
@import "theme/helper";
@import "theme/loading";
@import "theme/common/color";
@import "theme/common/border-colors";
@import "theme/common/typo";
@import "theme/common/ratio";
@import "theme/common/primeng";
@import "theme/common/animations";

// Helper classes and styles for special cases
@import "theme/components/ionic";
@import "theme/components/alerts";
@import "theme/components/badges";
@import "theme/components/checkbox";
@import "theme/components/dot";
@import "theme/components/gridster";
@import "theme/components/hint";
@import "theme/components/forms";
@import "theme/components/filters";
@import "theme/components/popovers";
@import "theme/components/user-popover";
@import "theme/components/modals";
@import "theme/components/pin-lock";
@import "theme/components/banner";
@import "theme/components/buttons";
@import "theme/components/card";
@import "theme/components/item";
@import "theme/components/segment-buttons";
@import "theme/components/swiper-progressbar";
@import "theme/components/table";
@import "theme/components/input";
@import "theme/components/select";
@import "theme/components/sidebar";
@import "theme/components/slider";
@import "theme/components/video";
@import "theme/components/tooltip";
@import "theme/components/markdown";
@import "theme/components/list";
@import "theme/components/quill";
@import "theme/components/skeleton";
@import "theme/components/toasts";
@import "theme/components/toolbar";
@import "theme/components/text";
@import "theme/components/calendar";
@import "theme/components/preview-product";
@import "theme/components/masonry";
@import "theme/components/widgets";
@import "theme/components/search-modal";
@import "theme/components/p-dropdown";

// Swiper base styles and ionic specific swiper styles to use the same css variables as ion-slides did
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/autoplay";
@import '@ionic/angular/css/ionic-swiper';

// Route Specific Styles
@import "theme/pages/settings";

// Quill styles
@import '~quill/dist/quill.core.css';

/* vjs-player.component.css */
@import '~video.js/dist/video-js.css';

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}


body {
  position: absolute; /* Fix to make min-width + scrolling possible */
  min-width: 100vw;
  overflow: auto;
}

@include media-query-small-tablet-and-below {

  body {
    position: relative;
  }

  ion-router-outlet {
    position: relative;
    //min-width: var(--app-content-min-width);
  }

  .ion-page {
    position: relative;
    min-width: 100%;
    width: auto;
    height: 100%;
    display: block;
    contain: unset;
    flex-direction: unset;
    overflow: visible;
  }
  .content-wrapper {
    position: relative;
    height: calc(100% - var(--cw-height)) ;
    contain: unset;
    padding-top: 0 !important;
  }
}

// Workaround for ion-alert scrollbar

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 4px;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: var(--ion-color-primary);
}
