.ratio-16-9 {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  .ratio-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.ratio-3-2{
  aspect-ratio: 3/2;
}