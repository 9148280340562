app-root {
  .grid-edit-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 5;
    // Allow scroll of background grid
    pointer-events: none !important;

    // Prevent pointer events on grid items when editing except the edited item
    & ~ gridster {
      gridster-item:not(.grid-item-edit) {
        pointer-events: none;
      }
    }
  }

  gridster {
    // Override margin settings from gridster
    padding-top: calc(var(--ion-padding-big) + var(--ion-padding-extra-small)) !important;
    padding-bottom: 0 !important; // Padding bottom not working, see .gridster-column:after
    padding-left: var(--ion-padding-big) !important;
    padding-right: var(--ion-padding-big) !important;
    margin-right: var(--ion-margin-small) !important;

    background: transparent;

    &.fit  {
      overflow: visible;
    }

    &.scrollVertical {
      // Prevent scrollbar in gridster element
      overflow: visible !important;
    }


    .gridster-row,
    .gridster-column {
      border: none;
    }

    // This trick is used to create a padding bottom
    .gridster-column:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -40px;
    }

    gridster-item {
      background: transparent;
      overflow: visible;

      &.grid-item-edit {
        z-index: 8 !important;
      }


      &.gridster-item-moving {
        border-radius: var(--default-border-radius);
      }

      &:hover .grid-item-overlay {
        opacity: 1;
        z-index: 10;
        background: rgba(0, 0, 0, 0.25);
      }

      .grid-item-overlay,
      .grid-item-overlay-reverted {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 0;
        border-radius: var(--default-border-radius);
        transition: opacity 250ms ease-in-out;

        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        justify-content: center;
        gap: var(--ion-padding-big);

        &.grid-item-overlay-reverted {
          opacity: 1;
          z-index: 10;
          background: var(--ion-color-background);
          padding: var(--ion-padding-medium-big);
          gap: var(--ion-padding-small);

          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        &.grid-item-overlay ion-button {
          --background: var(--ion-color-white);
          --border-radius: 50%;
          --padding-start: 0;
          --padding-end: 0;
          width: 55px;
          height: 55px;
          color: var(--ion-color-dark);
          border-radius: 50%;
          transition: color 250ms ease-in-out,
                      border 250ms ease-in-out;
          border: 2px solid transparent;

          &:hover {
            color: var(--ion-color-primary);
            border: 2px solid var(--ion-color-primary);
          }

          ion-icon {
            font-size: 27px;
            &[name='expand-outline'] {
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    // Custom empty element
    .gridster-empty-item {
      margin: 0;
      border: 2px dashed var(--ion-color-medium);
      border-radius: var(--default-border-radius);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--ion-color-white-shade);
      cursor: default;
      transition: background-color 450ms ease-in-out,
                  border 250ms ease-in-out;

      &:hover {
        background: var(--ion-color-primary-light);
        border-color: var(--ion-color-primary);

        ion-icon {
          color: var(--ion-color-primary);
        }
      }

      ion-icon {
        cursor: pointer;
        border-radius: 50%;
        background: var(--ion-color-white);
        color: var(--ion-color-medium);
        font-size: var(--ion-font-size-big);
        padding: var(--ion-padding-extra-small);
        transition: color 250ms ease-in-out;
      }
    }

    gridster-preview {
      background: var(--ion-color-primary-light);
      z-index: 1;
      border: 2px dashed var(--ion-color-primary);
      border-radius: var(--default-border-radius);
    }
  }
}
