@import './app/src/theme/mixins/media-query-mixin';

$logisticsHeaderHeight: 38.5px;
$logisticsHeaderHeightSmall: 35px;

.filters-container {
  display: flex;
  flex-direction: row;
  /*margin-bottom: clamp(10px, 1vh, 24px);
  margin-top: 4px;*/
  justify-content: space-between;

  @include media-query-normal-tablet {
    margin-bottom: 5px;
    flex-wrap: wrap;
    row-gap: var(--ion-padding-small);
  }

  & > * {
    flex: 0 1 auto;
    &:not(:last-child) {
      margin-right: var(--ion-margin-big);
    }
  }

  & > ion-searchbar {
    white-space: nowrap;
    overflow: hidden;
    max-width: 20rem;
    margin-left: 20px;

    .searchbar-input {
      border-radius: var(--default-input-border-radius);
      padding-inline-start: 1rem;
      -webkit-padding-start: 1rem;
      padding-inline-end: 3rem;
      -webkit-padding-end: 3rem;
      font-size: var(--orders-page-font-size);
      font-weight: var(--orders-page-font-weight);

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: var(--orders-page-font-size);
        font-weight: var(--orders-page-font-weight);
        opacity: 1;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: var(--orders-page-font-size);
        font-weight: var(--orders-page-font-weight);
        opacity: 1;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        font-size: var(--orders-page-font-size);
        font-weight: var(--orders-page-font-weight);
        opacity: 1;
      }
    }
    ion-icon.searchbar-search-icon {
      position: absolute;
      top: 50%;
      left: auto;
      right: 5px;
      transform: translate(-50%, -50%);
      z-index: 10;
      font-size: var(--ion-font-size-headline);
      color: var(--ion-color-medium)
    }

    &.searchbar-has-value {
      ion-icon.searchbar-search-icon {
        display: none !important;
      }

      button.searchbar-clear-button {
        display: block;
        position: absolute;
        top: 50%;
        left: auto;
        right: 5px;
        transform: translate(-50%, -50%);
        z-index: 10;
        font-size: var(--ion-font-size-headline);
        color: var(--ion-color-medium)
      }
    }
  }

  .help-button {
    display: flex;
    align-items: center;
    --padding-start: 0;
    --padding-end: 0;
    height: auto;

    ion-icon {
      background: var(--ion-color-medium);
      color: var(--ion-color-light);
      font-size: var(--ion-font-size-headline);
      border-radius: 50%;
      margin-right: .5rem;
    }
    ion-text {
      font-size: var(--orders-page-font-size);
      font-weight: var(--orders-page-font-weight);
      text-transform: uppercase;
      color: var(--ion-color-medium);
    }
  }

  .filter-button {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    --padding-start: .5rem;
    --padding-end: .5rem;
    --border-radius: var(--default-border-radius);

    @include media-query-normal-tablet {
      height: $logisticsHeaderHeightSmall;
    }

    ion-icon {
      font-size: var(--ion-font-size-headline-bigger);
    }

    &.active {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 10%;
        right: 0;
        height: .75rem;
        width: .75rem;
        background-color: var(--ion-color-primary);
        border-radius: 50%;
      }
    }
  }


  .main-action-button {
    height: var(--square-button-size);
    margin: 0 0 0 var(--ion-margin-medium);
    --padding-start: .5rem;
    --padding-end: .5rem;

    @include media-query-normal-tablet {
      height: $logisticsHeaderHeightSmall;
    }

    ion-icon {
      font-size: var(--ion-font-size-headline-bigger);
      @include media-query-normal-tablet {
        font-size: var(--ion-font-size-headline);
      }
    }
    ion-img {
      height: var(--ion-font-size-subheadline-bigger);
      @include media-query-normal-tablet {
        height: var(--ion-font-size-subheadline);
      }
    }
    &.disabled {
      --background: var(--ion-color-gray-light);
      --color: var(--ion-color-medium);
      opacity: 1;
    }
    &.main-action-button:first-of-type {
      margin: 0 0 0 auto
    }
  }

  .square-button {
    margin: 0 0 0 var(--ion-margin-medium);
    &:first-of-type {
      margin: 0 0 0 auto
    }
  }
}

.toggle-wrapper {
  max-height: 20px;
  ion-toggle {
    transform: scale(.6);
    top: -4px;
    left: -10px;
  }
}

.search-field-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: auto;
  min-width: 14rem;
  background: var(--ion-color-background);
  border-radius: var(--default-input-border-radius);
  cursor: pointer;
  position: relative;
  margin-left: auto;
  padding: 0 var(--ion-padding-small);

  @include media-query-mobile {
    min-width: 8rem;
  }

  @include media-query-small-tablet-and-below {
    min-width: 10rem;
    height: $logisticsHeaderHeightSmall;
  }

  ion-text, ion-input {
    font-size: var(--orders-page-font-size);
    font-weight: var(--orders-page-font-weight);
    background: var(--ion-color-background);
    border-radius: var(--default-input-border-radius);
    white-space: nowrap;
    overflow: hidden;
  }

  ion-text {
    padding-left: var(--ion-padding-small);
  }

  ion-input {
    left: 0;
    right: 0;
    width: 73%;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      box-shadow: 0 0 8px 5px #fff;
    }
  }

  ion-icon {
    z-index: 10;
    font-size: var(--ion-font-size-headline);
    color: var(--ion-color-medium)
  }
}

.filter-dropdown-search {
  display: flex;
  flex-direction: row;

  .filter-text {
    margin: 0;
    background: var(--ion-color-light-shade);
    padding: var(--ion-padding-small) var(--ion-padding-medium);
    border-radius: var(--default-border-radius) 0 0 var(--default-border-radius);
    white-space: nowrap;
  }

  .search-field-button {
    flex: 1;
    margin: 0;
    border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;

    &.stand-alone {
      border-radius: var(--default-border-radius);
    }
  }
}
