app-modal.search-modal {
  .modal-content {
    &, ion-grid ion-grid {
      padding: 0 !important;
    }
    ion-grid.search-filters {
      height: 100%;
      width: 100%;
      padding: 0;

      & > ion-row {
        &, & > ion-col {
          height: 100%;
        }

        & > ion-col:last-of-type {
          background: var(--ion-color-light);
        }
      }
    }

    ion-searchbar {
      --box-shadow: none;
      border-bottom: 1px solid var(--ion-color-dark);
      margin-bottom: var(--ion-margin-big);
    }
  }

  .modal-footer > div {
    width: 100%;
  }
}

app-modal {
    .searchbar-input-container {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    
    ion-searchbar {
        .searchbar-input.sc-ion-searchbar-md {
            font-weight: var(--orders-page-font-weight);
        }
        
        .searchbar-search-icon.sc-ion-searchbar-md {
            width: var(--ion-font-size-headline);
            height: var(--ion-font-size-headline);
            
            // vertically center icon
            top: 50%;
            transform: translateY(-50%);
        }
        
        .searchbar-clear-icon.sc-ion-searchbar-md {
            width: var(--ion-font-size-headline);
            height: var(--ion-font-size-headline);
        }
    }
}
