ion-button.button-solid{
  --box-shadow:none;
}

button.button-native {
  border-radius: 8px;
}

ion-button.button-with-icon {
  ion-icon,
  ion-img {
    padding-right: var(--ion-padding-small);
  }
}

ion-button {
  text-transform: none;
  box-shadow: none;
  border-radius: var(--default-input-border-radius);
  --border-radius: var(--default-input-border-radius);


  &.button-small {
    font-size: var(--ion-font-size-text-smaller);
    font-weight: var(--ion-font-weight-headline);
    --padding-start: 16px;
    --padding-end: 16px;

    &, &.button-solid {
      height: 25px;
      min-height: 20px;
    }

    &:hover {
      --background-focused: var(--ion-color-primary);
      --background-active: var(--ion-color-primary);
      --background-hover: var(--ion-color-primary);
    }
  }

  &.button-solid {
    box-shadow: none;
    font-weight: var(--ion-font-weight-headline);
    font-size: var(--ion-font-size-text-smaller);
    --padding-start: 16px;
    --padding-end: 16px;
    --border-radius: 8px;

    --padding-top: 0;
    --padding-bottom: 0;
    height: 36px;
    min-height: 20px;
  }

  &.button-large {
    font-size: var(--ion-font-size-text);
    font-weight: var(--ion-font-weight-headline);
    --padding-start: var(--ion-padding-big);
    --padding-end:  var(--ion-padding-big);

    &:hover {
      --background-focused: var(--ion-color-primary);
      --background-active: var(--ion-color-primary);
      --background-hover: var(--ion-color-primary);
    }
  }



  &.with-shadow {
    box-shadow: 4px 7px 33px 0 rgba(122,2,88,.24);
  }

  &.anchor-link {
    color: var(--ion-color-black);
    font-weight: var(--ion-font-weight-bolder);
    height: auto;
    margin: 0;
    --padding-start: .5rem;
    --padding-top: .5rem;
    --padding-bottom: .5rem;
    --padding-end: .5rem;


    &:hover {
      --background-focused: transparent;
      --background-active: transparent;
      --background-hover: transparent;
    }

    &.font-size-text {
      font-size: var(--ion-font-size-text);
    }

    &.inactive {
      color: var(--ion-color-medium);
      font-size: var(--ion-font-size-text);
      font-weight: var(--ion-font-weight-text);
    }

    ion-icon {
      color: var(--ion-color-light);
      margin-right: var(--ion-padding-small);
      font-size: var(--ion-font-size-headline);
    }

    &.anchor-link-underline {
      font-weight: var(--ion-font-weight-text);
      text-decoration: underline;
    }

    &.anchor-link-headline {
      letter-spacing: unset;
      font-weight: var(--ion-font-weight-headline);
    }

    &.mea {
      color: var(--mea-dark);
      ion-icon {
        color: var(--mea-dark);
      }
    }
  }

  &.button-secondary {
    --background: transparent;
    border: 1px solid var(--ion-color-primary);
    color: var(--ion-color-primary);
  }

  &.button-tertiary {
    color: var(--ion-color-black);
    --background: var(--ion-color-gray);

    &:hover {
      --background: var(--ion-color-medium-tint);
    }
  }

  &.square-button {
    width: var(--square-button-size);
    height: var(--square-button-size);
    --padding-start: .5rem;
    --padding-end: .5rem;

    ion-icon {
      font-size: var(--ion-font-size-headline-bigger);

      &[name='square-outline'] {
        --ionicon-stroke-width: 50px;
        height: 15px;
        width: 15px;
        flex-shrink: 0;
      }
    }
    ion-img {
      height: var(--ion-font-size-subheadline-bigger);
      @include media-query-normal-tablet {
        height: var(--ion-font-size-subheadline);
      }
    }
  }

  &.button-big {
    height: var(--square-button-size);
    margin-top: 0;
    margin-bottom:0;
    margin-right: 0;
    // do not add margin left because of new question on meamind!
  }

  &.icon-only {
    width: 2.25rem;
    height: 2.25rem;
    position: relative;

    ion-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }

  &.icon-text-button {
    ion-icon {
      padding-right: .25rem;
    }
  }

  &.icon-text-button {
    ion-icon {
      padding-right: .25rem;
    }
  }

  &.vertical-button {
    all: unset;

    position: absolute;
    right: 0;
    top: var(--ion-padding-big);

    --background: transparent;
    color: var(--ion-color-medium-tint);
    font-size: var(--ion-font-size-small-text);
    font-weight: var(--ion-font-weight-bolder);
    text-transform: uppercase;
    letter-spacing: 1px;
    --padding-start: 0;
    --padding-end: 0;

    transform-origin: right;
    transform: rotate(90deg) translate(93%,150%);
    transition: color 250ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      display: block;

      width: auto;
      height: 1px;
      left: 0;
      right: 0;
      bottom: -1px;

      background: var(--ion-color-light-shade);
    }
    &:hover {
      color: var(--ion-color-primary);
    }
  }

  &.vertical-popover-button {
    position: absolute;
    right: 0;
    top: 0;

    --background: var(--ion-color-light-shade);
    color: var(--ion-color-medium-contrast);
    font-size: var(--ion-font-size-small-text);
    font-weight: var(--ion-font-weight-bolder);
    text-transform: uppercase;
    letter-spacing: 1px;
    height: 25px;

    transform-origin: right;
    transform: rotate(90deg) translate(130%, -85%);
    transition: color 250ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      display: block;

      height: 1px;
      // 16px because button has 16px padding
      left: 16px;
      right: 16px;
      bottom: 6px;

      background: var(--ion-color-medium-contrast);
    }
    &:hover {
      color: var(--ion-color-primary);
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    --background: var(--ion-color-gray-light);
    color: var(--ion-color-black);
    &:hover {
      cursor: default;
    }
  }

  &.width-min-content {
    width: min-content;
    --padding-start: 0;
  }
}

.more-options {
  display: flex;
  align-items: center;
  margin-left: var(--ion-margin-medium);
  cursor: pointer;

  ion-icon {
    margin-left: var(--ion-margin-small);
  }
}

.options {
  font-size: var(--ion-font-size-big);
  color: var(--ion-color-black);
}

.form-modal-spinner{
  min-width: var(--ion-width-medium-big);
}

a.link-button {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  text-decoration: none;
  cursor: pointer;
  border-radius: var(--default-border-radius);
  padding: var(--ion-padding-small-big) var(--ion-padding-medium);
  font-size: var(--ion-font-size-nav-subheadline);
  font-weight: var(--ion-font-weight-headline);

  &.mea {
    background: var(--mea-dark);
    color: var(--ion-color-white);
  }
}
