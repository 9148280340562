ion-input.sc-ion-input-md-h,
ion-textarea.text-area {
  --highlight-color-focused: var(--ion-color-black);
}

input {
  caret-color: var(--ion-color-black);
}

.search-input {
  --background: var(--ion-color-light);
  border-radius: var(--default-border-radius);
  width: 20rem;
  max-width: 100%;
  margin-bottom: var(--ion-margin-medium);
  --padding-start: var(--ion-padding-medium);
  --padding-end: var(--ion-padding-medium);
  --padding-bottom: var(--ion-padding-medium);
  --padding-top: var(--ion-padding-medium);

  .searchbar-input {
    box-shadow: none;
    border-radius: var(--default-border-radius);
  }
}
/* For Firefox: Needs to be textfield otherwise arrows will appear */
input[type=number] {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ion-searchbar {
  &, &.sc-ion-searchbar-md-h, &.sc-ion-searchbar-ios-h {
    --box-shadow: none;
  }
}

ion-input {
  min-height: var(--min-height-input) !important;  // overwrite default ionic min-height
  cursor: default;

  .input-highlight {
    display: none;
  }
}

ion-input.border {
  .native-wrapper {
    border: 1px solid var(--ion-color-black);
    padding: 0 var(--ion-padding-medium);
  }
}
