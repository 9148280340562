@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}


