// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "fonts";
@import 'mixins/media-query-mixin';

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #b60258;
  --ion-color-primary-rgb: 182,2,88;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #a0024d;
  --ion-color-primary-shade-rgb: 160,2,77;
  --ion-color-primary-tint: #bd1b69;

  --ion-color-secondary: #6f009e;
  --ion-color-secondary-rgb: 111,0,158;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #62008b;
  --ion-color-secondary-tint: #c21bf6;

  --ion-color-tertiary: #b60258;
  --ion-color-tertiary-rgb: 182,2,88;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #a0024d;
  --ion-color-tertiary-tint: #bd1b69;

  --ion-color-success: var(--ion-color-dark);
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #333333;
  --ion-color-dark2: #333;
  --ion-color-dark-rgb: 51,51,51;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #2d2d2d;
  --ion-color-dark-tint: #474747;

  --ion-color-medium: #9e9e9e;
  --ion-color-medium-rgb: 158,158,158;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #8b8b8b;
  --ion-color-medium-tint: #a8a8a8;

  --ion-color-light: #efefef;
  --ion-color-light-transparent: rgba(239,239,239,0);
  --ion-color-light-rgb: 239,239,239;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d2d2d2;
  --ion-color-light-shade-rgb: 210,210,210;
  --ion-color-light-tint: #f1f1f1;

  --ion-color-black: #000000;
  --ion-color-white: #ffffff;
  --ion-color-white-shade: #f6f6f6;
  --ion-color-black-rgb: 0,0,0;

  --ion-color-green-dark: #076400;
  --ion-color-red-light: #feb1b1;
  --ion-color-red: #FF0000;

  --ion-color-lilac: #d2d7fc;
  --ion-color-lilac-rgb: 210, 215, 252;
  --ion-color-pink: #edd7d3;
  --ion-color-pink-rgb: 237, 215, 211;
  --ion-color-orange: #e25b57;
  --ion-color-orange-rgb: 226, 91, 87;

  /** ------------------------------------ .5px Border colors ------------------------------------ **/
  --border-color-thin-green-dark: rgba(7,100,0,.5);
  --border-color-thin-red: rgba(255,0,0,.5);

  /** ------------------------------------ Customization ------------------------------------ **/


  --ion-grid-columns: 24;
  --ion-font-family: 'Mulish',  sans-serif;
  --ion-text-color: var(--ion-color-dark);
  --ion-color-step-600: var(--ion-color-dark);


  /** ------------------------------------ Hints (not ngx-hints) ------------------------------------ **/
  --hint-dot-size: 20px;
  --hint-pulse-color-rgb: 255,206,0;

  /** ------------------------------------ Custom Variables ------------------------------------ **/

  --app-min-height: 768px;
  --app-min-width: 1024px;
  --app-content-min-height: 725px;
  --app-content-min-width: 700px;

  //Max height of modals
  --modal-content-max-height: 75vh;

  // Min height of app-widget-small footer
  --widget-footer-min-height: 33px;
  --widget-footer-tablet-min-height: 25px;

  // Min height of ion-input
  --min-height-input: 40px;

  //Special font sizes
  --ion-font-size-number-boxes: 4.5rem;
  --ion-font-size-number-boxes-tablet: 3.5rem;
  --ion-font-size-icon-number-boxes: 3.5rem;
  --ion-font-size-icon-number-boxes-tablet: 2.5rem;
  --ion-font-size-big: 2.2rem;

  --ion-font-size-icon-medium: 24px;

  --ion-font-size-pin-code: 2rem;

  --ion-font-size-card-title: 1.85rem;

  --ion-font-size-headline-bigger: 1.75rem;
  --ion-font-size-headline: 1.5rem;
  --ion-font-size-subheadline-bigger: 1.25rem;
  --ion-font-size-subheadline: 1.2rem;
  --ion-font-size-text: 1rem;
  --ion-font-size-small-text: 0.75rem;


  //Rare occurrence font sizes
  --ion-font-size-form: 16px;
  --ion-font-size-big-bigger: 3rem;
  --ion-font-size-big-smaller: 2rem;
  --ion-font-size-text-smaller: 12px;
  --ion-font-size-nav-subheadline: 13px;
  --ion-font-size-big-text: 32px;
  --ion-font-size-navigation: 15px;
  --ion-font-size-navigation-icon: 1.75rem;
  --ion-font-size-navigation-icon-small: 1.25rem;
  --ion-font-size-slider-card-content: 13px;

  --ion-font-size-icon-card-header: 20px;
  --ion-font-size-icon-footer: 1.25rem;
  --ion-font-size-notification-counter: 9px;

  --delivery-status-badge-font-size: .875rem;
  --interferer-head-font-size: .625rem;
  --interferer-content-font-size: .875rem;


  --ion-font-weight-bolder: 800;
  --ion-font-weight-boldest: 900;
  --ion-font-weight-headline: 700;
  --ion-font-weight-subheadline: 600;
  --ion-font-weight-text: 500;
  --ion-font-weight-small-text: 300;

  --orders-page-font-size: 1rem;
  --orders-page-font-weight: var(--ion-font-weight-headline);

  //Line height variables
  --ion-line-height-big: 1.5;
  --ion-line-height-small: 1.1;

  //custom padding variables
  --ion-padding-extra-extra-big: 4rem;
  --ion-padding-extra-big: 3rem;
  --ion-padding-big: 2rem;
  --ion-padding-medium-big: 1.5rem;
  --ion-padding-medium: 1rem;
  --ion-padding-small-big: 0.75rem;
  --ion-padding-small: 0.5rem;
  --ion-padding-extra-small: 0.3rem;
  --ion-padding-extra-smaller: 0.1rem;

  //custom margin variables
  --ion-margin-extra-big: 3rem;
  --ion-margin-big: 2rem;
  --ion-margin-medium-big: 1.5rem;
  --ion-margin-medium: 1rem;
  --ion-margin-small-big: 0.75rem;
  --ion-margin-small: 0.5rem;
  --ion-margin-extra-small: 0.3rem;
  --table-header-margin-bottom: 1.7rem;

  //custom width variables
  --ion-width-big: 10rem;
  --ion-width-medium-big: 8rem;
  --ion-width-medium: 5rem;
  --ion-width-small: 2.5rem;
  --ion-width-extra-small: 1rem;
  --ion-icon-default-width: 24px;

  //custom height and width variables
  --ion-height-big: 10rem;
  --ion-height-medium-big: 8rem;
  --ion-height-medium: 5rem;
  --ion-height-small: 2.5rem;
  --ion-height-extra-small: 1rem;
  --default-border-width: 1px;
  --menu-border-width: var(--default-border-width);
  --scrollbar-width: 1rem;
  --toolbar-height: 62px;
  --menu-button-width: 62px;
  --square-button-size: 2.75rem;
  --ion-icon-default-height: 24px;
  --input-fields-max-width: 415px;
  --logistics-header-height: 73px;
  --logistics-banner-height: 207px;
  --offers-banner-height: 115px;
  --headline-height: 300px;
  --content-block-max-height: 400px;
  --content-block-min-height: 200px;
  --footer-max-height: 400px;
  --vertical-banner-width: 240px;
  --menu-contracted-width: 62px;
  --menu-expanded-width: 230px;
  --menu-extensions-max-width: 1024px;
  --form-label-width: 180px;
  --input-popover-min-width: 220px;

  @include media-query-small-tablet-and-below {
    --menu-expanded-width: 210px;
  }


  --ion-color-primary-light: #f0dee7;
  --ion-color-primary-light-rgb: 255,237,245;
  --ion-color-primary-light-contrast: #000000;
  --ion-color-primary-light-contrast-rgb: 0,0,0;
  --ion-color-primary-light-shade: #d3c3cb;
  --ion-color-primary-light-tint: #f2e1e9;

  --ion-color-primary-dark: #920045;
  --ion-color-primary-dark-rgb: 146,0,69;
  --ion-color-primary-dark-contrast: #ffffff;
  --ion-color-primary-dark-contrast-rgb: 255,255,255;
  --ion-color-primary-dark-shade: #80003d;
  --ion-color-primary-dark-tint: #9d1a58;

  --ion-color-secondary-light: #eed1fc;
  --ion-color-secondary-light-rgb: 238,209,252;
  --ion-color-secondary-light-contrast: #000000;
  --ion-color-secondary-light-contrast-rgb: 0,0,0;
  --ion-color-secondary-light-shade: #d1b8de;
  --ion-color-secondary-light-tint: #f0d6fc;

  --ion-color-gray-light: #e5e5e5;
  --ion-color-gray-light-rgb: 229,229,229;
  --ion-color-gray: #d2d2d2;
  --ion-color-gray-rgb: 210,210,210;

  --ion-color-green: #00c10e;
  --ion-color-green-rgb: 0,193,14;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255,255,255;
  --ion-color-green-shade: #00aa0c;
  --ion-color-green-tint: #1ac726;

  --ion-color-blue: #0055a4;
  --ion-color-blue-rgb: 0,85,164;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,255,255;
  --ion-color-blue-shade: #043663;
  --ion-color-blue-shade-rgb: 4,54,99;
  --ion-color-bluen-tint: #0071d5;  // TODO: this is a temporary value, correct value needs to be defined

  --ion-color-orange: #FF4E50;

  --ion-color-background: #ffffff;

  --gradient-background: linear-gradient(157deg, var(--ion-color-primary) 0%, #720036 90%);
  --bottom-shadow-background-to-top: linear-gradient(to top, rgba(255,255,255,0) 0%,rgba(255,255,255,.9) 50%,rgba(255,255,255,1) 100%);
  --bottom-shadow-background-to-bottom: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,.9) 50%,rgba(255,255,255,1) 100%);

  --active-tab-background-color: linear-gradient(to top, var(--ion-color-primary-light-tint) 0%, var(--widget-card-background) 50%);
  --active-tab-background-color-blue: linear-gradient(to top, var(--communication-zone-light) 0%, var(--widget-card-background) 50%);
  --active-hover-tab-background-color-blue: linear-gradient(to top, var(--communication-zone-light) 0%, var(--ion-color-light) 50%);
  --active-tab-background-color-mea: linear-gradient(to top, var(--mea-dark-20) 0%, var(--widget-card-background) 50%);
  --active-hover-tab-background-color-mea: linear-gradient(to top, var(--mea-dark-20) 0%, var(--ion-color-light) 50%);
  --active-hover-tab-background-color: linear-gradient(to top, var(--ion-color-primary-light-tint) 0%, var(--ion-color-light) 50%);
  --hover-tab-background-color: var(--ion-color-light);

  --default-box-shadow: 1px 1px 7px 2px rgba(38, 38, 38, 0.03),
                        2px 2px 12px 3px rgba(38, 38, 38, 0.03),
                        3px 3px 20px 4px rgba(38, 38, 38, 0.03),
                        4px 4px 25px 5px rgba(38, 38, 38, 0.03);
  --default-box-shadow-dark: 1px 1px 7px 2px rgba(38, 38, 38, 0.05),
                             2px 2px 12px 3px rgba(38, 38, 38, 0.05),
                             3px 3px 20px 4px rgba(38, 38, 38, 0.05),
                             4px 4px 25px 5px rgba(38, 38, 38, 0.05);
  --default-box-shadow-primary: 0px 0px 5px 0px rgba(182,2,88, 0.1),
                                0px 0px 12px 1px rgba(182,2,88, 0.1),
                                0px 0px 16px 2px rgba(182,2,88, 0.1),
                                0px 0px 24px 3px rgba(182,2,88, 0.1);

  --offers-cart-grad-info-box-shadow: 1px 1px 4px 1px rgba(38, 38, 38, 0.05),
                                      1px 2px 7px 3px rgba(38, 38, 38, 0.05),
                                      2px 4px 10px 4px rgba(38, 38, 38, 0.04),
                                      3px 5px 14px 5px rgba(38, 38, 38, 0.03);
  --user-popover-box-shadow:
          0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14),
          0 3px 14px 2px rgba(0, 0, 0, 0.12);

  --appointment-line-shadow: 3px 6px 17px 0;
  --appointment-line-shadow-small: 1px 4px 5px 1px ;

  --default-border-color: var(--ion-color-light);
  --default-border-radius: 8px;
  --default-input-border-radius: 8px;
  --small-border-radius: 5px;


  --table-page-max-width: 1500px;


  /** Account Avatar Status Colors **/
  --avatar-status-online: var(--ion-color-green);


  --content-background: var(--ion-color-light);
  --widget-card-background: #fff;
  --menu-border-color: var(--ion-color-light);
  --menu-icon-color: var(--ion-color-dark);
  --menu-icon-background-active: rgba(var(--ion-color-primary-light-rgb),.8);
  --menu-button-margin: 0;
  --toolbar-icon-background-active: rgba(var(--ion-color-primary-rgb),.5);


  --status-colors-yellow: #FEECB9;
  --status-colors-yellow-dark: #98813E;
  --status-colors-orange: #F8D8BD;
  --status-colors-orange-dark: #AE6E37;
  --status-colors-green: #d2ecc2;
  --status-colors-green-dark: #778d82;
  --status-colors-blue: #C8EAF3;
  --status-colors-blue-dark: #009FB7;
  --status-colors-purple: #DCC8F5;
  --status-colors-purple-dark: #85517B;
  --status-colors-grey: #efefef;
  --status-colors-grey-dark: #6D6D6D;


  --progress-bar-color-1-pain-therapy: var(--status-colors-green);
  --progress-bar-color-2-pain-therapy: var(--status-colors-green-dark);
  --progress-bar-color-1-alternative-medicine: var(--status-colors-blue);
  --progress-bar-color-2-alternative-medicine: var(--status-colors-blue-dark);
  --progress-bar-color-1-general-knowledge: var(--status-colors-purple);
  --progress-bar-color-2-general-knowledge: var(--status-colors-purple-dark);

  --progress-bar-background: var(--ion-color-light);
  --progress-bar-background-active: var(--ion-color-primary);

  --chat-message-color-support: var(--ion-color-light);
  --chat-message-color-user: var(--status-colors-green);

  --meamind-active-item-gradient: linear-gradient(to right, var(--ion-color-primary-light-tint) 0%, var(--widget-card-background) 30%);

  --feedback-button: #0055a4;
  --mea-dark: #058550;
  --mea-dark-rgb: 5,133,80;
  --mea-dark-20: #cce5df;
  --mea-light: #97bf0d;
  --communication-zone-dark: #0055a4;
  --communication-zone-light: #eaf3fb;
  --graduated-prices-information-icon: #f8b010;

  --user-administration-chat-color: #FF4E50;
  --user-administration-shop-color: #7985FF;

  --badge-disabled-color: #bdbdbd;

  --sanacorp-academy-color: #f9aed0;

  --statistics-chart-secondary: #750038;
  --statistics-bar-chart-positive: #0055a4;
  --statistics-bar-chart-positive-light: #0055A414;

  --offers-card-yellow: #eccb12;


  /**************************************/
  /* CUSTOMIZABLE WIDGETS               */
  /**************************************/

  --customizable-widget-footer-height: 40px;
  --customizable-widget-footer-tablet-height: 30px;

  /**************************************/
  /* LIBRARIES                          */
  /**************************************/

  --ngx-hint-pulse-color-rgb: var(--ion-color-medium-rgb);
  --ngx-hint-dot-size: 20px;
  --ngx-hint-bg: var(--ion-color-background);
  --ngx-hint-shadow: 0 3px 30px rgb(33 33 33 / 30%);
  --ngx-hint-padding: var(--ion-padding-medium);
  --ngx-hint-arrow-size: 10px;
  --ngx-hint-button-padding: var(--ion-padding-small-big);
  --ngx-hint-button-bg: var(--ion-color-primary);
  --ngx-hint-button-color: var(--ion-color-primary-contrast);
  --ngx-hint-button-border-radius: var(--default-border-radius);
  --ngx-hint-button-fs: var(--ion-font-size-text-smaller);
  --ngx-hint-button-fw: var(--ion-font-weight-headline);
}

@include media-query-small-tablet-and-below {
    * {font-size: 13px;}
}

@include media-query-normal-tablet {
    * {font-size: 14px};
}

@include media-query-big-tablet {
    * {font-size: 14px};
}

@include media-query-web {
    * {font-size: 14px};
}
