.p-dropdown-items-wrapper {
  background: var(--ion-color-white);
  border-radius: var(--default-border-radius);

  ul {
    text-align: right;
    padding: 0;

    p-dropdownitem {
      li {
        padding: var(--ion-padding-small) var(--ion-padding-small-big);

        &:hover {
          background: var(--ion-color-primary-light);
        }
      }
    }
  }
}
