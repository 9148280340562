.masonry-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;

  .masonry-columns {
    display: grid;
    height: fit-content;
  }
}
