app-pin-unlock {
  flex: 1 1 100%;
  height: 100%;
  display: block;
}

.locked-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .locked-container {
    flex: 1 1 100%;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-light);
  }
}
