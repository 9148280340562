ion-alert {
  &.warning .alert-message .important {
    font-size: var(--ion-font-size-text);
    font-weight: var(--ion-font-weight-boldest);
  }
  &.medium {
    --min-width: 22rem;
  }
  &.sc-ion-alert-md-h,
  &.sc-ion-alert-ios-h {
    --max-width: 280px;
  }
  .alert-message.sc-ion-alert-md,
  .alert-message.sc-ion-alert-ios  {
    font-size: var(--ion-font-size-text);
  }
  input.alert-input.alert-input-highlight {
    border-bottom: 2px solid var(--ion-color-primary);
  }
  .alert-radio-group.sc-ion-alert-md,
  .alert-checkbox-group.sc-ion-alert-md,
  .alert-radio-group.sc-ion-alert-ios,
  .alert-checkbox-group.sc-ion-alert-ios {
    max-height: 266px;
  }
  .alert-radio-label.sc-ion-alert-md,
  .alert-radio-label.sc-ion-alert-ios {
    font-size: var(--ion-font-size-form);
  }
}
