div.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.required-fields {
  font-size: var(--ion-font-size-text-smaller);
  margin-top: var(--ion-margin-small);
  font-weight: var(--ion-font-weight-headline);

  & > span {
    color: var(--ion-color-primary);
  }
}

.form {
  .form-field-container {
    position: relative;
    padding-bottom: 2px;
    margin-bottom: 4px;

    .field-limit {
      text-align: right;
      font-size: var(--ion-font-size-small-text);
      color: var(--ion-color-medium);
      position: absolute;
      right: 152px;

      &.large {
       right: 16px;
      }
    }
  }

  .animatedInputLabel {
    position: relative;

    ion-label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: font-size 250ms ease-in-out,
      top 250ms ease-in-out;
      z-index: 1;
    }

    &.notIonInput ion-label {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(0.625rem);
      transition: font-size 250ms ease-in-out,
      top 250ms ease-in-out;
      z-index: 1;
    }

    ion-input.has-focus + ion-label,
    ion-input.has-value + ion-label {
      font-size: var(--ion-font-size-small-text);
      top: 0;
    }

    input:-webkit-autofill + ion-label,
    input:focus + ion-label,
    input.has-value + ion-label {
      font-size: var(--ion-font-size-small-text);
      top: -1.875rem;
    }
  }

  ion-item {
    --border-color: transparent !important;
    --highlight-height: 0px;
    --highlight-background: transparent;
    --background: none;
    --padding-start: 0;
    --ion-safe-area-left: 0;
    background: var(--ion-color-background);
    border-radius: 0;

    &.item .label-fixed {
      &.sc-ion-label-md-h, &.sc-ion-label-ios-h {
        flex: 0 0 130px;
      }
    }

    ion-label,
    .label {
      color: var(--ion-color-dark);
      font-weight: var(--ion-font-weight-headline);
      font-size: var(--ion-font-size-form)!important;
      min-width: 170px;
      max-width: 170px;
      white-space: normal;
    }

    span.required {
      @extend .required-star
    }

    ion-icon {
      height: 22px;
      width: 22px;
      cursor: pointer;
    }


    &.item-interactive-disabled ion-label {
      opacity: 1!important;
    }

    ion-input.ion-no-margin label .label-text-wrapper {
      margin: 0 !important;
    }

    ion-input,
    ion-input input,
    ion-textarea,
    ion-textarea div,
    ion-select,
    app-date-selection-widget,
    markdown,
    .ion-checkbox-container,
    app-meamind-tags-field,
    p,
    ion-grid.ion-grid-container ion-col {
      --color: var(--ion-color-dark);
      --padding-start: var(--ion-padding-medium) !important;
      font-size: var(--ion-font-size-form) !important;
    }
    ion-textarea div.preContent,
    markdown.preContent {
      padding-left: var(--ion-padding-medium);
    }
    app-date-selection-widget {
      padding-left: var(--ion-padding-medium);
      width: 100%;
      cursor: pointer;

      ion-icon {
        position: absolute;
        right: 10px;
        height: 12px;
        // remove if https://github.com/ionic-team/ionic-framework/issues/17446 is fixed
        // and set opacity 1 in --placeholder-opacity
        opacity: .33;
      }
    }
    div.ion-input-container,
    div.ion-textarea-container,
    div.ion-select-container,
    div.ion-checkbox-container,
    div.markdown-container,
    ion-input div.native-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      overflow: auto;
    }
    div.ion-quill-editor-container {
      width: 100%;
      overflow: auto;

      quill-editor {
        width: 100%;
        height: 100%;

        div.ql-toolbar {
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }

        div.ql-container {
          height: 100%;
          min-height: 180px;
          border: 0;

          div.ql-editor {
            min-height: 180px;
          }

          div.ql-tooltip.ql-editing,
          div.ql-tooltip {
            left: unset !important; // Tooltips currently flow out of the editor (left and right), hence reset tooltip to left side
          }
        }
      }

      ion-icon {
        position: absolute;
        right: 16px;
        top: 4px;
      }
    }
    div.ion-select-container ion-select,
    div.markdown-container markdown {
      width: 100%;
      max-width: 100%;
    }
    div.markdown-container markdown {
      padding-top: var(--ion-padding-small);
      &> *:first-child {
        padding-top: 0;
        margin-top: 0;
      }
    }
    div.ion-textarea-container {
      & > div,
      & > ion-textarea  {
        margin-top: 10px;
        caret-color: var(--ion-color-black)!important;
        textarea {
          --padding-top: 2px;
          caret-color: var(--ion-color-black)!important;
        }
      }

      ion-textarea {
        --padding-start: var(--ion-padding-medium);
      }

      & > div {
        padding-left: var(--ion-padding-small-big);
        padding-right: var(--ion-padding-small-big);
        padding-bottom: var(--ion-padding-medium);
        color: var(--ion-color-medium-shade);
        font-size: var(--ion-font-size-form);
      }
    }

    ion-text.ion-text-container,
    ion-grid.ion-grid-container,
    div.ion-textarea-container,
    div.ion-select-container,
    div.markdown-container,
    div.ion-quill-editor-container {
      border: 1px solid var(--ion-color-gray);

      ::placeholder {
        font-size: var(--ion-font-size-text-smaller);
      }
    }

    div.ion-input-container {
      ion-input {
        --highlight-color-focused: var(--ion-color-dark);
        --highlight-color-invalid: var(--ion-color-primary);
        --border-color: var(--ion-color-gray);

        input::placeholder {
          font-size: var(--ion-font-size-text-smaller);
        }

        .native-wrapper {
          padding-right: var(--ion-padding-big);
        }
      }
    }

    //
    // ion-input with inner label to look like ion-label old
    //
    ion-input .input-wrapper {
      padding-left: 0 !important;
      padding-inline-end: 0 !important;
    }

    ion-input .native-wrapper {
      padding-left: var(--ion-padding-medium);
      border: 1px solid var(--ion-color-gray) !important;
      background-color: var(--ion-color-background);

      .native-input::placeholder {
        font-size: var(--ion-font-size-text-smaller);
      }
    }

    ion-input.has-icon .native-wrapper {
      padding-right: var(--ion-padding-big);
    }

    ion-input.is-date .native-wrapper {
      .native-input {
        padding-left: var(--ion-padding-extra-small);
        padding-right: var(--ion-padding-extra-small);
      }
    }

    ion-input.has-focus .native-wrapper {
      border: 2px solid var(--ion-color-dark) !important;
      --highlight-color: black;
    }

    ion-input.invalid .native-wrapper {
      border-color: var(--ion-color-primary) !important;
      color: var(--ion-color-primary);
      --highlight-color: var(--ion-color-primary);
    }

    ion-input.gray .native-wrapper {
      background: var(--ion-color-light);

      .native-input {
        //disabled look of text
        opacity: 0.38;
      }
    }

    ion-input .label-text-wrapper {
      @extend ion-label;
      gap: var(--ion-padding-extra-small);
      margin-inline-end: 0 !important;
      min-width: var(--form-label-width) !important;

      .label-text {
        font-size: var(--ion-font-size-form) !important;
      }
    }

    ion-input.required .label-text-wrapper::after {
      content: "*";
      color: var(--ion-color-primary);
    }

    ion-label.required::after {
      content: "*";
      color: var(--ion-color-primary);
      margin-left: var(--ion-margin-extra-small);
    }

    //used in date-range-picker
    ion-input.small-label{
      .label-text-wrapper {
        min-width: unset !important;
        margin-inline-end: var(--ion-margin-medium) !important;
      }

      .native-wrapper {
        padding-left: 0;
      }
    }

    div.ion-input-container ion-icon,
    div.ion-textarea-container ion-icon {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }

    ion-grid.ion-grid-container.disabled ion-col.long-text:before {
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--ion-color-light) 80%);
    }
    ion-text.ion-text-container.disabled,
    ion-grid.ion-grid-container.disabled,
    ion-grid.ion-grid-container.disabled ion-row,
    div.ion-input-container.disabled,
    div.ion-textarea-container.disabled,
    div.ion-select-container.disabled,
    div.markdown-container.disabled,
    div.ion-quill-editor-container.disabled {
      background-color: var(--ion-color-light);
      border-bottom-color: var(--ion-color-gray);
    }

    ion-text.ion-text-container.disabled p,
    div.ion-input-container.disabled ion-input,
    div.ion-select-container.disabled ion-select,
    div.ion-select-container.disabled ion-text {
      // do not add ion-col or ion-textarea otherwise the user cannot scroll
      pointer-events: none;
    }
    div.ion-input-container.disabled ion-input input,
    div.ion-textarea-container.disabled ion-textarea textarea  {
      opacity: 1;
    }

    ion-text.ion-text-container.disabled p {
      margin: 10px var(--ion-padding-medium);
    }

    div.ion-textarea-container.invalid {
      border: 1px solid var(--ion-color-primary);
    }

    div.ion-quill-editor-container:focus-within {
      border: 1px solid var(--ion-color-dark);
      &.invalid {
        border: 1px solid var(--ion-color-primary);
      }
    }

    div.ion-input-container.invalid ion-input,
    div.ion-textarea-container.invalid ion-textarea,
    div.ion-select-container.invalid ion-select,
    div.ion-checkbox-container.invalid ion-checkbox {
      color: var(--ion-color-primary);
    }

    div.ion-quill-editor-container.invalid .ql-editor.ql-blank::before {
      color: var(--ion-color-primary);
      opacity: 0.5;
    }

    div.ion-textarea-container.invalid ion-icon,
    div.ion-input-container.invalid ion-icon,
    div.ion-select-container.invalid ion-icon,
    div.ion-checkbox-container.invalid ion-icon,
    div.ion-quill-editor-container.invalid ion-icon {
      color: var(--ion-color-primary);
      margin: 10px 16px;
    }

    ion-checkbox {
      --border-radius: 0;
      --size: var(--ion-font-size-headline);
      align-self: center;
    }
    app-meamind-tags-field {
      display: block;
      width: 100%;
      min-height: 70%;

      ion-input {
        border: none;
      }
    }

    ion-label,
    .label {
      font-weight: var(--ion-font-weight-headline);
      margin: 15px 0;
      &.margin-big {
        margin: 20px 0;
      }
      &.sc-ion-label-md-h, &.sc-ion-label-ios-h {
        align-self: flex-start;
      }
    }

    ion-textarea {
      --padding-bottom: 10px;
      margin-bottom: 5px;

      // see: https://github.com/ionic-team/ionic-framework/issues/21242
      &.auto-grow {
        min-height: 200px;
        textarea {
          min-height: 200px;
        }
      }
    }
    ion-select {
      padding: var(--ion-padding-small) var(--ion-padding-big);
      margin: 0;
    }

    .input-container {
      padding: var(--ion-padding-small) var(--ion-padding-medium);
    }
    .form-divider {
      margin: 0 var(--ion-margin-medium);
      height: 1px;
      width: 100%;
      background-color: var(--ion-color-medium);
    }
  }

  ion-item.background-input-large .label-fixed {
    &.sc-ion-label-md-h, &.sc-ion-label-ios-h {
      flex: 0 0 160px;
    }
  }

  ::ng-deep.background-input.wysiwyg {
    height: 100%;
    display: flex;

    .item-inner {
      padding-right: 0;
    }
  }
}

ion-item.dropdown-list-wrapper {
  overflow: visible;

  div.ion-input-container {
    position: relative;
    overflow: visible;
  }
}

.zero-z-index {
  z-index: 0;
}


ion-label.label-min-width {
  &, span {
    min-width: 180px !important;
    width: 180px !important;
  }
}

//
// ion-textarea designed to make label look like ion-label
//
ion-textarea .textarea-wrapper {
  padding-left: 0 !important;
}

ion-textarea .label-text-wrapper {
  @extend ion-label;
  display: flex;
  gap: var(--ion-padding-extra-small);
  min-width: var(--form-label-width) !important;
  margin-right: 0 !important;
  padding: 0 !important;
  // margin von ion-label
  margin: 15px 0 !important;
}

ion-textarea.required .label-text-wrapper::after {
  content: "*";
  color: var(--ion-color-primary);
}

ion-textarea.invalid .textarea-wrapper-inner {
  border: 1px solid var(--ion-color-primary) !important;

  .native-wrapper {
    color: var(--ion-color-primary) !important;
  }
}

ion-textarea.gray .textarea-wrapper-inner {
  background-color: var(--ion-color-light);
  .native-wrapper {
    //disabled look of text
    opacity: 0.38;
  }
}

ion-textarea.large .textarea-wrapper-inner {
  @extend .large;
}

.form ion-item ion-textarea.pre-content div.textarea-wrapper-inner {
  flex-direction: column;
}

ion-textarea .textarea-wrapper-inner {
  @extend .ion-textarea-container;
  background-color: var(--ion-color-background);

  .start-slot-wrapper {
    padding: 0 !important;
  }
  .end-slot-wrapper {
    padding: 0 !important;
  }

  .native-wrapper {
    padding-left: var(--ion-padding-medium) !important;
    color: var(--ion-color-dark) !important;
  }
}

.form-search-field {
  --box-shadow: none;
  border-bottom: 2px solid var(--ion-color-dark);
  padding-top: calc(var(--ion-padding-small-big) + var(--ion-padding-medium));
  @include media-query-normal-tablet {
      padding-top: calc(var(--ion-padding-small) + var(--ion-padding-medium));
  }

  .searchbar-input {
    padding: 0 0 0 var(--ion-padding-big);
    font-weight: 700;
    color: var(--ion-color-dark-tint);
  }

  ion-icon.searchbar-search-icon {
    --icon-color: var(--ion-color-medium);
    height: var(--ion-font-size-headline);
    width: var(--ion-font-size-headline);
    bottom: 5px;
    top: auto;
    left: 0;
  }
  ion-icon.searchbar-clear-icon {
    --icon-color: var(--ion-color-medium);
    height: var(--ion-font-size-headline);
    width: var(--ion-font-size-headline);
    position: absolute;
    bottom: 5px;
    top: auto;
    right: 0;
  }
}

ion-select.select-disabled {
    background: var(--ion-color-light-shade);
}
