.text-with-link-button {
  display: inline-flex;
  align-items: center;
  margin-right: var(--ion-padding-medium);
}


.footnote-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;

}

ion-text.footnote {
  font-size: var(--ion-font-size-small-text);
  font-weight: var(--ion-font-weight-subheadline);
  color: var(--ion-color-medium-shade);
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: var(--default-border-radius);
  z-index: 1002;
  background: var(--ion-color-background);

  ion-text h1, .h1, &h1, &.h1 {
    color: var(--ion-color-medium);
    margin-top: 0;
    margin-bottom: var(--ion-margin-medium-big);
    font-weight: var(--ion-font-weight-text);
    text-align: center;
  }
}

.no-results-small {
  display: flex;
  height: 100%;

  ion-text h1, .h1, &h1, &.h1 {
    display: block;
    margin: auto;
    font-size: var(--ion-font-size-text);
    font-weight: var(--ion-font-weight-text);
    text-align: center;
  }

  &.opacity-fade-in {
    animation: opacity-fade-in 0.3s ease-in-out;
  }
}

@keyframes opacity-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

ion-input.note-widget {
  .native-wrapper {
    padding: var(--ion-padding-medium-big) var(--ion-padding-medium-big);
    --placeholder-color: var(--ion-color-medium);
    font-size: var(--ion-font-size-headline);
    font-weight: var(--ion-font-weight-subheadline);

  }
}

ion-textarea.note-widget {
  --placeholder-color: var(--ion-color-medium);
  height: 100%;

  .textarea-wrapper-inner {
    height: 100%;

    .native-wrapper {
      padding: 0 !important;

      & textarea {
        padding: 0 var(--ion-padding-medium-big) var(--ion-padding-medium-big) !important;
        font-size: var(--ion-font-size-subheadline-bigger);
        line-height: 1.8;
      }
    }
  }
  .textarea-highlight {
    display: none;
  }
}
