ion-badge {
  border-radius: 5px;
}

ion-icon[size="medium"] {
 font-size: var(--ion-font-size-icon-medium);
}

ion-icon{
  &.disabled{
    opacity: 0.5;
  }
}