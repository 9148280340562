.settings-label {
  font-size: var(--ion-font-size-headline);
  font-weight: var(--ion-font-weight-headline);
  display: block;
  margin-bottom: 1rem;
}
.settings-label-item {
  font-size: var(--ion-font-size-text);
  font-weight: var(--ion-font-weight-subheadline);
  display: block;
}
.settings-select-label {
  flex: 0 0 auto;
}

.settings-list {
  max-width: 50rem;
  ion-item {
    --padding-start: 0
  }
}

.setting-item {
  margin-bottom: 2rem;

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ion-toggle {
    padding-left: 0;
    margin-right: 1rem;
  }

  ion-list {
    &.settings-list-bordered {
      border: 1px solid var(--ion-color-light-shade);
      border-radius: 5px;
    }

    &:not(.settings-list-bordered) ion-item {
      --padding-start: 0;
    }

    ion-item {
      --min-height: 0;

      ion-checkbox {
        margin: var(--ion-margin-small) 0;

      }
      ion-checkbox::part(label) {
        margin-top: 0;
        margin-bottom: 0;
      }

      ion-select {
        margin-left: var(--ion-margin-big);
        padding: var(--ion-margin-small) var(--ion-margin-medium) var(--ion-margin-small) var(--ion-margin-small);
      }
    }
  }
}

.settings-divider {
  width: 100%;
  height:1px;
  background: var(--ion-color-light-shade);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
