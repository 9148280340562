.default-loader {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: auto;
  z-index: 1001;

  &.with-bg-light {
    background: rgba(var(--ion-color-light-rgb), .4);
  }

  &.is-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  ion-spinner, ion-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ion-spinner {
    height: 10rem;
    width: 10rem;
  }
}

.loading-skeleton-wrapper {
  height: 100%;

  .smaller-width {
    width: 25%;
  }

  .small-width {
    width: 33%;
  }

  .default-width-xs{
    width: 40%;
  }

  .default-width {
    width: 50%;
  }

  .default-width-xl {
    width: 55%;
  }

  .medium-width-xs {
    width: 65%;
  }

  .medium-width {
    width: 75%;
  }

  .medium-width-xl {
    width: 85%;
  }

  .full-width {
    width: 100%;
  }

  ion-skeleton-text {

    &.smaller-height {
      padding-bottom: var(--ion-padding-small);
    }

    &.small-height {
      padding-bottom: var(--ion-padding-medium);
    }

    &.default-height {
      padding-bottom: var(--ion-padding-medium-big);
    }

    &.medium-height {
      padding-bottom: var(--ion-padding-big);
    }

    &.big-height {
      padding-bottom: var(--ion-padding-extra-big);
    }
  }

  .loading-skeleton-row {

    &.with-border-bottom {
      &:not(:last-child) {
        border-bottom: 1px solid var(--ion-color-light);
      }
    }

    .loading-skeleton-inner-container {
      padding: var(--ion-padding-small);

      &.grid-container {
        display: flex;

        &.row {
          flex-direction: row;
        }

        &.space-between {
          & > div {
            &:nth-child(odd) {
              padding-right: var(--ion-padding-big);
            }
          }
        }
      }
    }
  }
}
