@import './app/src/theme/mixins/media-query-mixin';

.toolbar-action-buttons {
  & > ion-button {
    margin-right: 1.25rem;
    --border-radius: .5rem;
    --overflow:visible;

    @include media-query-normal-tablet {
      margin-right: 0;
    }

    &.icon-only {
      width: var(--square-button-size);
      height: var(--square-button-size);
      --padding-start: .5rem;
      --padding-end: .5rem;
      --padding-top: .5rem;
      --padding-bottom: .5rem;
    }

    & ion-avatar {
      position: relative;
      height: 3rem;
      width: 3rem;
      margin: 0.1rem 1.5rem 0.2rem 0.2rem;

      &:before {
        content: '';
        height: .625rem;
        width: .625rem;
        background-color: var(--avatar-status-online);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    &:not(.not-resizing):not(.icon-text-button):not(.icon-only) {
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
    }

    &:not(.not-resizing) {
      ion-icon {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    &.toolbar-add-action-button {
      ion-text {
        font-size: var(--ion-font-size-text-smaller);
        font-weight: var(--ion-font-weight-headline);
      }
    }

    &.toolbar-action-notification {
      position: relative;
      overflow: visible;
      height: var(--toolbar-height);

      @include media-query-normal-tablet {
        &:last-of-type {
          margin-right: 0.9rem;
        }
      }

      ion-icon {
        height: var(--ion-padding-big);
        width: var(--ion-padding-medium-big);
        margin: 0;

        &.offline-hint {
          left: 100%;
          cursor: pointer;
          height: 28px;
          width: 28px;
          top: 11px;
          color: var(--ion-color-primary-contrast);
          z-index: 1;
        }
      }

      ion-text {
        overflow: visible;
        font-size: var(--ion-font-size-notification-counter);
        font-weight: var(--ion-font-weight-bolder);
        letter-spacing: .5px;
        border-radius: 50%;
        height: var(--ion-padding-medium-big);
        width: var(--ion-padding-medium-big);
        position: absolute;
        top: 0;
        right: -10px;
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      }
    }

    &.profile-button {
      margin-left: var(--ion-padding-medium);
      margin-right: 0;
      display: flex;
      height: 100%;
      min-width: 280px;

      & > div {
        width: 100%;
      }

      * {
        pointer-events: none;
      }

      .actions-container {
        text-align: left;
        flex: 1 1 auto;

        .skeleton-user-mail {
          width: 120px;
        }
      }

      .button-inner {
        flex-flow: column;
      }

      ion-label {
        display: flex;
        margin: 0;
        font-size: var(--ion-font-size-navigation);
        font-weight: var(--ion-font-weight-subheadline);
      }

      .store-name {
        display: inline-block;
        color: var(--ion-color-medium-shade);
        margin-top: var(--ion-margin-extra-small);
        font-size: var(--ion-font-size-text-smaller);
      }

      ion-icon {
        font-size: var(--ion-font-size-big-text);
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }
}
