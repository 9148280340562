.hint-dot {
  z-index: 4;

  &:before {
    content: '';
    display: block;
    width: var(--hint-dot-size, 20px);
    height: var(--hint-dot-size, 20px);
    background-color: rgb(var(--hint-pulse-color-rgb, 200 202 12));
    border-radius: 50%;
    animation: 2s hint-pulse infinite;
    cursor: pointer;
  }
}

@keyframes hint-pulse {
  0% {
    box-shadow: rgba(var(--hint-pulse-color-rgb, 200, 202, 12), 1) 0 0 0 0;
  }
  100% {
    box-shadow: rgba(var(--hint-pulse-color-rgb, 200, 202, 12), 0) 0 0 0 var(--hint-dot-size, 20px);
  }
}
