@import './app/src/theme/mixins/media-query-mixin';
.sidebar {
  padding: var(--ion-padding-medium);
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--ion-color-gray-light);
  border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;

  &.is-modal {
    border-radius: 0;
  }
  div.details-container {
    display: flex;
    flex: 1 1 100%;
    min-height: 0;

    .scroll-container {
      flex: 1;
      overflow: auto;
    }

    .headline {
      margin-bottom: var(--ion-margin-medium);
      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-text {
        font-size: var(--ion-font-size-big);
        font-weight: var(--ion-font-weight-headline);
      }

      ion-icon {
        height: var(--ion-font-size-big);
        flex-shrink: 0;
      }
    }

    .label-with-icon {
      .label {
        display: inline-block;
      }
      ion-icon {
        vertical-align: middle;
      }
    }
    ion-text {
      &.label, &.value {
        display: block;
      }

      &.label {
        font-size: var(--ion-font-weight-text);
        font-weight: var(--ion-font-weight-subheadline);
        color: var(--ion-color-medium);
        margin-bottom: .25rem;
      }

      &.value {
        font-size: var(--ion-font-size-subheadline);
        font-weight: var(--ion-font-weight-headline);

        &.filterable {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    ion-grid, ion-row, ion-col {
      padding: 0;
    }

    .divider {
      margin:  var(--ion-margin-medium) 0;
    }
  }

  div.actions-container {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 7px;
    border-top: 1px solid var(--ion-color-light-shade);

    ion-icon {
      font-size: var(--ion-font-size-big);
      transition: color 200ms ease-in-out;

      &:hover {
        color: var(--ion-color-primary);
      }
    }

    & > div > ion-text{
      font-weight: var(--ion-font-weight-small-text);
    }
  }
}
