.preview-wrapper {
  width: 100%;
  overflow: auto;

  .preview-bg {
    background-image: url('/assets/images/meadirekt-preview-bg.png');
    background-size: 140%;
    background-repeat: no-repeat;
    background-position: 30% 220%;
    padding-top: var(--ion-padding-medium-big);
    padding-left: var(--ion-padding-extra-big);
    padding-bottom: var(--ion-padding-extra-big);

    & > ion-col:last-child {
      padding: var(--ion-padding-big);
    }

    .preview {
      width: 100%;
      padding-top: 62%;
      position: relative;
      // add background-image: url('assets/images/your-image.png') in component style file
      background-size: cover;
      background-repeat: no-repeat;
      box-shadow: inset 0 0 1rem 0.4rem rgba(0,0,0,0.05),
                  inset 0 0 2rem 1.2rem rgba(0,0,0,0.05),
                  inset 0 0 3rem 2rem rgba(0,0,0,0.1);
    }
  }

  .h1-bigger {
    margin-top: var(--ion-margin-medium);
    display: block;
  }

  ion-text {
    li, p {
      font-size: var(--ion-font-size-text);
      font-weight: var(--ion-font-weight-subheadline);
      line-height: 1.2;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0;

    li {
      position: relative;
      margin-bottom: var(--ion-margin-small);

      &:before {
        content: "";
        background: url(/assets/icons/chevron-forward-outline.svg);
        background-size: cover;
        display: block;
        width: 1em;
        height: 1em;
        margin-left: -1.25em;
        position: absolute;
        left: 0;
        top: 1px;
      }
    }
  }
}
