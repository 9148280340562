.widget-info {
  font-size: var(--ion-font-size-headline);
  margin-left: var(--ion-margin-extra-small);
  margin-right: var(--ion-margin-small);
  color: var(--ion-color-medium);
}

.tooltip {
  position: absolute;
  max-width: 250px;
  font-size: var(--ion-font-size-text-smaller);
  text-align: center;
  color: var(--ion-color-danger-contrast);
  padding: var(--ion-padding-medium);
  background: var(--ion-color-dark);
  border-radius: var(--default-border-radius);
  z-index: 1000;
  opacity: 0;
  top: 50%;

  &:after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  &.tooltip-top:after {
    top: 100%;
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: black transparent transparent transparent;
  }

  &.tooltip-bottom:after {
    bottom: 100%;
    left: calc(50% - 5px);
    top: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent black transparent;
  }

  &.tooltip-left:after {
    top: calc(50% - 5px);
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent black;
  }

  &.tooltip-right:after {
    top: calc(50% - 5px);
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent black transparent transparent;
  }

  &.tooltip-bottom-left:after {
    bottom: 100%;
    left: 30px;
    top: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent black transparent;
  }

  &.tooltip-red {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    font-weight: var(--ion-font-weight-headline);  // increased font-weight for better readability

    &.tooltip-top:after {
      border-color: var(--ion-color-primary) transparent transparent transparent;
    }

    &.tooltip-bottom:after {
      border-color: transparent transparent var(--ion-color-primary) transparent;
    }

    &.tooltip-left:after {
      border-color: transparent transparent transparent var(--ion-color-primary);
    }

    &.tooltip-right:after {
      border-color: transparent var(--ion-color-primary) transparent transparent;
    }

    &.tooltip-bottom-left:after {
      border-color: transparent transparent var(--ion-color-primary) transparent;
    }
  }


  &.tooltip-show {
    opacity: 1;
  }

  ul {
    margin-left: 0;
    padding-left: 20px;
    text-align: left;

    &, & * {
      font-size: var(--ion-font-size-text-smaller);
    }
  }
}
