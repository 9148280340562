.p-calendar {
  width: 100%;
}
.p-datepicker {
  padding: 0.5rem;
  background: transparent;
  color: var(--ion-color-black);
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: var(--ion-padding-small);
  color: var(--ion-color-black);
  background: transparent;
  font-weight: 600;
  margin: 0;
  display: flex;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: var(--ion-color-black);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  border-color: transparent;
  background: var(--ion-color-light);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  flex: 1 1 100%;
  text-align: center;
  line-height: 2rem;
  border-radius: var(--default-border-radius);
  background: var(--ion-color-light);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: var(--ion-color-black);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-size: var(--ion-font-size-navigation);
  font-weight: 600;
  padding: 0.5rem;
}/*
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #9C27B0;
}*/
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: var(--i0on-font-size-navigation);
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
  font-weight: var(--ion-font-weight-small-text);
}
.p-datepicker table td {
  padding: var(--ion-padding-extra-small);
  text-align: center;
}
.p-datepicker table td > span {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  border: 1px solid transparent;
  position: relative;
  overflow: visible;

  &,
  & span {
    font-size: var(--ion-font-size-subheadline);
  }

  &.p-disabled {
    color: var(--ion-color-gray);
  }

  .has-date:after {
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -5px;
    background: var(--ion-color-primary);
  }
}
.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker table td.p-datepicker-today > span {
  background: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);

  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: transparent;
    border: 2px solid var(--ion-color-primary);
    border-radius: 50%;
  }

  &,
  & span {
    font-weight: var(--ion-font-weight-headline);
  }
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: var(--ion-color-black);
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: var(--ion-font-size-navigation);
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary);
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #6c757d;
  right: 0.5rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #6c757d;
  right: 2.857rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}

/* month-picker */
p-calendar[view="month"] {
  position: relative;

  &:after {
    content: url(/assets/icons/caret-down-sharp.svg);
    display: inline-block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .p-inputtext {
    width: 5rem; // width of month year to month year input
    background: unset;
    font-weight: var(--ion-font-weight-subheadline);
    border: 1px solid transparent;
    border-radius: var(--default-border-radius);
    box-shadow: none;
    cursor: pointer;
    transition: all 250ms ease-in-out;
  }

  .p-datepicker-monthpicker {
    width: 22rem; // preferable width of month year picker
  }

  --month-gap: var(--ion-padding-extra-small);
  .p-monthpicker {
    display: flex;
    flex-wrap: wrap;
    gap: var(--month-gap);
  }

  .p-monthpicker-month {
    flex-basis: calc(33% - var(--month-gap));
  }

  .p-disabled {
    color: var(--ion-color-medium);
  }
}
